import React, {useCallback, useMemo} from 'react'
import './app.less'
import './index.css'
import './i18n'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import {useTranslation} from 'react-i18next'
import {matchPath, useHistory, useLocation} from 'react-router'
import {RouteDefinitions} from './App'
import {useDispatch, useSelector} from 'react-redux'
import {clearSite, inEnterpriseViewSelector, meSelector, selectedSiteSelector} from '../authentication/redux'
import {currentPageSelector} from './navigation/redux'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {Box} from '@mui/material'

export const Bcs = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const site = useSelector(selectedSiteSelector)
    const inEnterpriseView = useSelector(inEnterpriseViewSelector)
    const me = useSelector(meSelector)
    const page = useSelector(currentPageSelector)
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    const goToEnterpriseView = useCallback(
        async () => {
            await dispatch(clearSite());
            history.push("/")
            return null
        }, [dispatch, history])

    const crumbs = useMemo(() => {
        let path = [RouteDefinitions.find(def => matchPath(pathname, def.href))]
        if (path) {
            path = RouteDefinitions.reduce((previous, current) => (previous[0]?.parentId === current.id ? [current, ...previous] : previous), path)
        }

        return [
            <Link key={'crumb-company'} sx={{ display: 'flex', alignItems: 'center' }} color='inherit'
                  underline={'none'} onClick={goToEnterpriseView} href={'#'}>
                <LocationCityIcon fontSize='inherit' />
                {me && me.customerName}
            </Link>,
        ...(inEnterpriseView? [] : [<Link key={'crumb-site'} sx={{ display: 'flex', alignItems: 'center' }}
                  color={pathname !== '/site' ? 'inherit' : 'text.primary'} href={'/site'} underline={'none'}>
                <LocationOnIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                {site?.name}
            </Link>]),
            path?.map(route =>
                    (!route?.href || !['/site'].includes(route?.href)) && route?.translationKey ? (
                    <Link key={`crumb-${route.id} `} sx={{ display: 'flex', alignItems: 'center' }}
                          color={page ? 'inherit' : 'text.primary'} href={route.href} underline={'none'}>
                        {route.icon && <route.icon fontSize='inherit' sx={{ mr: 0.5 }} />}
                        {t(`${route.translationKey}`)}
                    </Link>
                ) : undefined,
            ),
            page ? (
                <Box key={'crumb-detail'} color={'text.primary'}>
                    {page.label}
                </Box>
            ) : undefined,
        ]
    }, [page, site, t, pathname, me, goToEnterpriseView, inEnterpriseView])

    return (
        <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon fontSize='small' />}>
            {crumbs}
        </Breadcrumbs>
    )
}
