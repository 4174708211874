import React, {ReactElement, useMemo} from 'react'
import {TFunction, useTranslation} from 'react-i18next'
import {AnswerGroupForm, BasicUser, ExtensionValidationRule, Language} from '../app/types'
import {useForm, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from '../common/form/TextInput'
import FormRow from '../common/form/FormRow'
import FormFieldBox from '../common/form/FormFieldBox'
import {useFormSubmission} from '../common/servercall/useFormSubmission'
import ErrorAlert from '../common/components/ErrorAlert'
import FormGroup from '../common/form/FormGroup'
import {useSelector} from 'react-redux'
import {
    allowedUserNcosSelector,
    extensionValidationRulesSelector,
    nrOfExtensionDigitsSelector,
    selectedSiteSelector
} from '../authentication/redux'
import {
    getAutocompleteSiteAnnouncements,
    getAutocompleteSiteAnnouncementsWithDefaultSelection,
    getAutocompleteSiteNumbers,
    getAutocompleteUsersForProduct
} from '../common/util/helpers'
import FormContainer from '../common/form/FormContainer'
import {Box, Paper} from '@mui/material'
import {sort, split} from 'ramda'
import {formatUserInDropdown} from '../common/util/formatters'
import {SelectiveCallForwardForm, selectiveForwardSchema} from '../common/components/SelectiveCallForwardForm'
import FormCheckbox from '../common/form/FormCheckbox'
import {emailRegex, extensionRegex} from "../users/UserForm";
import {useTableWithAdjustableOrder} from "../common/components/table/useTableWithAdjustableOrder";
import {ForwardToNumberField} from "../common/components/ForwardToNumberField";
import {usePhoneNumbersToForwardTo} from "../common/servercall/usePhoneNumbersToForwardTo";

const schema = (t: TFunction, nrOfExtensionDigits: number, extensionValidationRules: ExtensionValidationRule[]) =>
    yup
        .object()
        .shape({
            name: yup.string().trim().max(30, 'too-long').required('required-m'),
            language: yup.string().required('required-f'),
            extension: yup.lazy(value => {
                let schema = yup
                    .string()
                    .trim()
                    .required('required-f')
                    .matches(extensionRegex(nrOfExtensionDigits), {
                        message : t("general.form-error.invalid-extension", {nrOfExtensionDigits}),
                        excludeEmptyString: true
                    })
                extensionValidationRules.forEach(rule => {
                    schema = schema.test({
                        name: 'test',
                        test: value => !value || !new RegExp(`^${rule.regex}$`).test(value),
                        message: rule.errorKey,
                    })
                })
                return schema.nullable()
            }),
            number: yup
                .string()
                .matches(/^$|^\+32[0-9]{8}$|^0[0-9]{8}$/, 'invalid-phone-number')
                .nullable(),
            ncos: yup.string().required('required-f'),
            overflowNumber: yup
                .string()
                .when(['overflowDestination'], {
                    is: (overflowDestination: string) => overflowDestination === 'CALL_FORWARD',
                    then: schema => yup.string().trim().max(22, 'too-long').matches(/^\+?[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).required('required-m').nullable(),
                    otherwise: schema => yup.string().trim().nullable(),
                })
            ,
            overflowTimeout: yup
                .number()
                .when(['answerGroupType'], {
                    is: (answerGroupType: string) => answerGroupType === 'QUEUE',
                    then: schema => yup.number().required('required-f').typeError('invalid-overflow-timeout').min(5, 'invalid-overflow-timeout').max(600, 'invalid-overflow-timeout'),
                    otherwise: schema => yup.number().notRequired().nullable(),
                })
                .nullable(),
            forwardTimeoutSeconds: yup
                .number()
                .when(['answerGroupType'], {
                    is: (answerGroupType: string) => answerGroupType !== 'QUEUE',
                    then: schema => yup.number().required('required-f').typeError('invalid-overflow-timeout').min(5, 'invalid-overflow-timeout').max(600, 'invalid-overflow-timeout'),
                    otherwise: schema => yup.number().notRequired().nullable(),
                })
                .nullable(),
            overflowAnnouncementFileName: yup
                .string()
                .when(['overflowDestination'], {
                    is: (overflowDestination: string) => overflowDestination === 'BUSY_TREATMENT',
                    then: schema => yup.string().required('required-f').nullable(),
                    otherwise: schema => yup.string().nullable(),
                })
                .nullable(),
            voicemailEmail: yup
                .string()
                .when(['voicemailUsage'], {
                    is: (voicemailUsage: string) => ['FORWARD_TO_EMAIL', 'NOTIFY_BY_EMAIL'].includes(voicemailUsage),
                    then: schema => yup.string().trim().max(70, 'too-long').required('answergroup-email-required-when-voicemail').matches(
                        emailRegex,
                        'invalid-email'
                    ),
                    otherwise: schema => yup.string().trim().max(70, 'too-long').matches(
                        emailRegex,
                        'invalid-email'
                    ).nullable(),
                })
                .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, {
                    message: 'invalid-email',
                    excludeEmptyString: true,
                }),
            additionalVoicemailEmails: yup.string().trim().max(400, 'too-long')
                .matches(/^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(\s*;\s*|\s*$))*$/, {
                    message: 'invalid-email',
                    excludeEmptyString: true,
                }).nullable(),
            voicemailEnabled: yup.bool(),
            agentRingTime: yup.number().required('required-m'),
            huntPolicy: yup.string().required('required-m'),
            userAgents: yup.array().when(['answerGroupType'], {
                is: (answerGroupType: string) => answerGroupType === 'QUEUE',
                then: schema => yup.array().min(1, 'agent-min-1-required'),
                otherwise: schema => yup.array(),
            }),
            welcomeMessageFileName: yup.string().nullable(),
            musicOnHoldFileName: yup.string().nullable(),
            comfortMessageFileName: yup.string().nullable(),
            comfortMessageGapTime: yup.number().typeError('invalid-frequency-comfort-message').min(10, 'invalid-frequency-comfort-message').max(999, 'invalid-frequency-comfort-message').nullable(),
            selectiveCallForward: selectiveForwardSchema,
        })
        .required()

type Props = {
    flow: AnswerGroupForm
    onSubmit: (formData: AnswerGroupForm) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

export const FlowForm = ({ flow, onSubmit, readonly, buildFormButtons }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const allowedUserNcos = useSelector(allowedUserNcosSelector)
    const extensionValidationRules = useSelector(extensionValidationRulesSelector)
    const nrOfExtensionDigits = useSelector(nrOfExtensionDigitsSelector)
    const {phoneNumbersToForwardTo}= usePhoneNumbersToForwardTo(site)

    const { control, handleSubmit, setError, setValue, getFieldState } = useForm<AnswerGroupForm>({
        resolver: yupResolver<yup.AnyObjectSchema>(schema(t, nrOfExtensionDigits, extensionValidationRules)),
        defaultValues: {
            ...flow,
        },
    })

    const userOptionParams = useMemo(() => {
        return getAutocompleteUsersForProduct(
            t('flows.edit.no-users-found'),
            [],
            site
        )
    }, [site, t])

    const { table: AgentTable, selector: AgentSelector } = useTableWithAdjustableOrder<BasicUser>('userAgents', userOptionParams, control, formatUserInDropdown, user => user.uuid, t('users.edit.user-select-button'))

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<AnswerGroupForm>(onSubmit, setError)

    const languageOptions = useMemo(
        () =>
            Object.values(Language).map(language => {
                return {
                    value: language,
                    label: t(`users.language.${language}`),
                }
            }),
        [t]
    )

    const numberOptionParams = useMemo(() => {
        return getAutocompleteSiteNumbers(t, false, site, flow?.number)
    }, [flow, site, t])

    const ringTimePerAgentOptions = useMemo(
        () =>
            [6, 12, 18, 24, 30, 42, 60, 120].map(seconds => ({
                value: `${seconds}`,
                label: `${seconds} ${t('general.seconds-abbrev')}`,
            })),
        [t]
    )

    const queueLengthOptions = useMemo(() => {
        return Array.from({ length: 20 }, (_, index) => index + 1).map(seconds => ({
            value: seconds,
            label: `${seconds}`,
        }))
    }, [])

    const huntPolicyOptions = useMemo(
        () =>
            ['CIRCULAR', 'REGULAR', 'SIMULTANEOUS', 'UNIFORM'].map(policy => ({
                value: `${policy}`,
                label: `${t(`flows.edit.hunting-policy-names.${policy}`)}`,
            })),
        [t]
    )

    const voicemailUsageOptions = useMemo(
        () =>
            ['FORWARD_TO_EMAIL', 'NOTIFY_BY_EMAIL', 'WITHOUT_EMAIL'].map(usage => ({
                value: `${usage}`,
                label: `${t(`flows.edit.voicemail-usages.${usage}`)}`,
            })),
        [t]
    )

    const overflowDestinationOptions = useMemo(
        () =>
            ['VOICEMAIL', 'CALL_FORWARD', 'BUSY_TREATMENT'].map(destination => ({
                value: `${destination}`,
                label: `${t(`flows.edit.overflow-destination-names.${destination}`)}`,
            })),
        [t]
    )

    const overflowDestinationValue = useWatch({
        control,
        name: 'overflowDestination',
    })

    const voicemailUsageValue = useWatch({
        control,
        name: 'voicemailUsage',
    })

    const musicOnHoldFileName = useWatch({
        control,
        name: 'musicOnHoldFileName',
    })


    const welcomeMessageOptionParams = useMemo(() => {
        return getAutocompleteSiteAnnouncements(t, 2000, site, flow.welcomeMessageFileName ?? undefined)
    }, [flow, site, t])

    const musicOnHoldMessageOptionParams = useMemo(() => {
        return getAutocompleteSiteAnnouncementsWithDefaultSelection(t, 5000, site, flow.musicOnHoldFileName ?? undefined)
    }, [flow, site, t])

    const voicemailNoAnswerAnnouncementOptionParams = useMemo(() => {
        return {
            ...getAutocompleteSiteAnnouncementsWithDefaultSelection(t, 2000, site, flow.voicemailNoAnswerAnnouncement ?? undefined),
            disableClearable: true,
        }
    }, [flow, site, t])

    const callForwardAnnouncementOptionParams = useMemo(() => {
        return getAutocompleteSiteAnnouncements(t, 2000, site, flow.callForwardAnnouncement ?? undefined)
    }, [flow, site, t])

    const comfortMessageOptionParams = useMemo(() => {
        return getAutocompleteSiteAnnouncements(t, 2000, site, flow.comfortMessageFileName ?? undefined)
    }, [flow, site, t])

    const overflowAnnouncementOptionParams = useMemo(() => {
        return getAutocompleteSiteAnnouncements(t, 2000, site, flow.overflowAnnouncementFileName ?? undefined)
    }, [flow, site, t])

    const ncosOptions = useMemo(
        () =>
            sort(
                (a, b) =>
                    a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    }),
                split(',', allowedUserNcos)
            ).map(ncos => ({ label: t(`users.ncos-names.${ncos}`), value: ncos })),
        [t, allowedUserNcos]
    )

    return (
        <>
            <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('flows.edit.section.info')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} autofocus={true} label={t('flows.edit.name')} name={'name'} control={control} required={true} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.language')} name={'language'} control={control} required={true} options={languageOptions} />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('flows.edit.number')} name={'number'} control={control} required={false} autoCompleteOptions={numberOptionParams} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput label={t('flows.edit.extension')} name={'extension'} control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        {flow.answerGroupType === 'QUEUE' ? (

                                <FormFieldBox>
                                    <TextInput readonly={readonly} label={t('flows.edit.queue-length')} name={'queueLength'} control={control} required={false} options={queueLengthOptions} />
                                </FormFieldBox>

                        ) : (
                            <></>
                        )}
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.ncos')} name={'ncos'} control={control} required={true} options={ncosOptions} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.prio-external-calls')}
                                name={'prioExternalCalls'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('flows.edit.section.voicemail')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('flows.edit.voicemail-enabled')}
                                name={'voicemailEnabled'}
                                checked={overflowDestinationValue === 'VOICEMAIL'}
                                disabled={overflowDestinationValue === 'VOICEMAIL'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        {flow.answerGroupType === 'QUEUE' ? (
                            <FormFieldBox>
                                <TextInput readonly={readonly} label={t('flows.edit.overflow-timeout')} name={'overflowTimeout'} control={control} required={true} />
                            </FormFieldBox>
                        ) : (
                            <FormFieldBox>
                                <TextInput readonly={readonly} label={t('flows.edit.forward-timeout')} name={'forwardTimeoutSeconds'} control={control} required={true} />
                            </FormFieldBox>
                        )}
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('flows.edit.voicemail-usage')} name={'voicemailUsage'} control={control} required={true} options={voicemailUsageOptions} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('flows.edit.voicemail-email')} name={'voicemailEmail'} control={control} required={voicemailUsageValue !== 'WITHOUT_EMAIL'} />
                        </FormFieldBox>
                        {voicemailUsageValue === 'FORWARD_TO_EMAIL' &&
                            <FormFieldBox fullWidth={true}>
                                <TextInput help={t('flows.edit.additional-voicemail-emails-help')} readonly={readonly} label={t('flows.edit.additional-voicemail-emails')} name={'additionalVoicemailEmails'} control={control} />
                            </FormFieldBox>
                        }
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('flows.edit.section.agents')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox mdSize={8} lgSize={6}>
                            {AgentSelector}
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={12}>
                            <Paper elevation={2}>
                                <AgentTable />
                            </Paper>
                        </FormFieldBox>
                    </FormRow>
                    <Box width={1} mt={2}></Box>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('flows.edit.ring-time-per-agent')} name={'agentRingTime'} control={control} required={true} options={ringTimePerAgentOptions} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('flows.edit.hunt-policy')} name={'huntPolicy'} control={control} required={true} options={huntPolicyOptions} />
                        </FormFieldBox>
                    </FormRow>

                    <FormRow>
                        {flow.answerGroupType === 'QUEUE' && (
                            <FormFieldBox>
                                <FormCheckbox label={t('flows.edit.allow-agent-logoff')} name={'allowAgentLogoff'} control={control} setValue={setValue} />
                            </FormFieldBox>
                        )}
                        <FormFieldBox>
                            <FormCheckbox label={t('flows.edit.allow-call-waiting-for-agents')} name={'allowCallWaitingForAgents'} control={control} setValue={setValue} />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>

                <FormGroup label={t('flows.edit.section.media')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput
                                help={t('general.max-file-size', {size: 2})}
                                readonly={readonly}
                                label={t('flows.edit.welcome-message')}
                                name={'welcomeMessageFileName'}
                                control={control}
                                required={false}
                                autoCompleteOptions={welcomeMessageOptionParams}
                            />
                        </FormFieldBox>
                    </FormRow>
                    {flow.answerGroupType === 'QUEUE' && (
                        <FormRow>
                            <FormFieldBox>
                                <TextInput
                                    help={t('general.max-file-size', {size: 5})}
                                    readonly={readonly}
                                    label={t('flows.edit.music-on-hold')}
                                    name={'musicOnHoldFileName'}
                                    control={control}
                                    required={false}
                                    autoCompleteOptions={musicOnHoldMessageOptionParams}
                                />
                            </FormFieldBox>
                            {!!musicOnHoldFileName && (<FormFieldBox>
                                <FormCheckbox
                                    label={t('flows.edit.play-ringing-when-offering-call')}
                                    name={'playRingingWhenOfferingCall'}
                                    control={control}
                                    setValue={setValue}
                                    getFieldState={getFieldState}
                                />
                            </FormFieldBox>)}
                        </FormRow>
                    )}
                    {flow.answerGroupType === 'QUEUE' && (
                        <FormRow>
                            <FormFieldBox>
                                <TextInput
                                    help={t('general.max-file-size', {size: 2})}
                                    readonly={readonly}
                                    label={t('flows.edit.comfort-media-file')}
                                    name={'comfortMessageFileName'}
                                    control={control}
                                    required={false}
                                    autoCompleteOptions={comfortMessageOptionParams}
                                />
                            </FormFieldBox>
                            <FormFieldBox>
                                <TextInput
                                    readonly={readonly}
                                    label={t('flows.edit.comfort-media-frequency')}
                                    name={'comfortMessageGapTime'}
                                    control={control}
                                    required={false}
                                />
                            </FormFieldBox>
                        </FormRow>
                    )}
                </FormGroup>

                <FormGroup label={t('flows.edit.section.overflow')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput
                                readonly={readonly}
                                label={t('flows.edit.overflow-destination')}
                                name={'overflowDestination'}
                                control={control}
                                required={false}
                                options={overflowDestinationOptions}
                                isRadio={true}
                            />
                        </FormFieldBox>
                    </FormRow>
                    {overflowDestinationValue === 'VOICEMAIL' && (
                        <FormRow>
                            <FormFieldBox>
                                <TextInput
                                    help={t('general.max-file-size', {size: 2})}
                                    readonly={readonly}
                                    label={t('flows.edit.voicemail-greeting')}
                                    name={'voicemailNoAnswerAnnouncement'}
                                    control={control}
                                    required={true}
                                    autoCompleteOptions={voicemailNoAnswerAnnouncementOptionParams}
                                />
                            </FormFieldBox>
                        </FormRow>
                    )}
                    {overflowDestinationValue === 'CALL_FORWARD' && (
                        <FormRow>
                            <FormFieldBox>
                                <ForwardToNumberField
                                    phoneNumbersToForwardTo={phoneNumbersToForwardTo}
                                    defaultNumber={flow.overflowNumber}
                                    readonly={readonly}
                                    required={true}
                                    label={t('flows.edit.overflow-number')}
                                    name={'overflowNumber'}
                                    control={control}
                                />
                            </FormFieldBox>
                            <FormFieldBox>
                                <TextInput
                                    help={t('general.max-file-size', {size: 2})}
                                    readonly={readonly}
                                    label={t('flows.edit.call-forward-announcement')}
                                    name={'callForwardAnnouncement'}
                                    control={control}
                                    required={false}
                                    autoCompleteOptions={callForwardAnnouncementOptionParams}
                                />
                            </FormFieldBox>
                        </FormRow>
                    )}
                    {overflowDestinationValue === 'BUSY_TREATMENT' && (
                        <FormRow>
                            <FormFieldBox>
                                <TextInput
                                    help={t('general.max-file-size', {size: 2})}
                                    readonly={readonly}
                                    label={t('flows.edit.overflow-destination-names.BUSY_TREATMENT')}
                                    name={'overflowAnnouncementFileName'}
                                    control={control}
                                    required={true}
                                    autoCompleteOptions={overflowAnnouncementOptionParams}
                                />
                            </FormFieldBox>
                        </FormRow>
                    )}
                </FormGroup>
                <SelectiveCallForwardForm control={control} selectiveCallForward={flow.selectiveCallForward} nightServiceMode={flow.nightServiceMode} phoneNumbersToForwardTo={phoneNumbersToForwardTo} fullWidth={true}/>
                {!readonly && buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
    )
}
