import React, {useCallback, useMemo, useState} from 'react'
import {TitledBody} from '../common/components/TitledBody'
import {useTranslation} from 'react-i18next'
import {NumberRange, TrunkingSite} from "../app/types";
import {meSelector} from "../authentication/redux";
import {useSelector} from "react-redux";
import {ColumnDefinition, InfiniteScrollTable} from "../common/components/table/Table";
import {List, ListItem} from "@mui/material";
import {sorterHeader} from "../common/components/table/SorterHeader";
import {always, ifElse, isEmpty} from "ramda";
import {Box, Flex} from "rebass";
import {MoreHoriz} from "@mui/icons-material";
import ClickableTooltip from "../common/components/ClickableTooltip";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import {formatPhoneNumber} from "../common/util/formatters";

const formatRange = (nr: NumberRange) => `${formatPhoneNumber(nr.startNumber)} - ${formatPhoneNumber(nr.stopNumber)}`

const formatRangeArray = (ranges: NumberRange[]) => ifElse(isEmpty, always(""), ranges => formatRange(ranges[0]))(ranges)

const formatAllFromRangeArray = (ranges: NumberRange[]) =>
    <List dense={true}>
        {ranges.map(rn => <ListItem><ListItemText primary={formatRange(rn)}/></ListItem>)}
    </List>

export const TrunkingSites = () => {
    const { t } = useTranslation()
    const me = useSelector(meSelector)

    const [currentSort, setCurrentSort] = useState<{field: keyof TrunkingSite, order: any}>({field: 'productId', order: 'asc'})

    const columns: ColumnDefinition<TrunkingSite>[] = useMemo(
        () => [
            {
                title: t('trunking-sites.table.product-id'),
                key: 'productId',
                sorter: true,
                render: (text: string, record) => <>{text}</>,
            },
            {
                title: t('trunking-sites.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string, record) => <>{text}</>,
            },
            {
                title: t('trunking-sites.table.type'),
                key: 'type',
                sorter: true,
                render: (text: string, record) => <>{t(`trunking-sites.product-types.${text}`)}</>,
            },
            {
                title: t('trunking-sites.table.nr-of-channels'),
                key: 'nrOfCalls',
                sorter: true,
                render: (text: string, record) => <>{text}</>,
            },
            {
                title: t('trunking-sites.table.number-ranges'),
                key: 'numberRanges',
                sorter: false,
                render: (text: string, record) =>
                    <Flex justifyContent={"flex-start"} alignItems={"center"} >
                        <Box ><span style={{whiteSpace: "nowrap"}}>{formatRangeArray(record.numberRanges)}</span></Box>
                        {record.numberRanges.length > 1 ? <Box><ClickableTooltip title={formatAllFromRangeArray(record.numberRanges)} buttonFn={(onClick) => <IconButton size="small" color="secondary" aria-label="df" component="span" onClick={onClick}>
                            <MoreHoriz/>
                        </IconButton>}/></Box> : <></>}
                    </Flex>,
            }
            ,
        ],
        [t]
    )

    const sort_by = (field : any, reverse : boolean, primer: ((a: string | null)  => string | undefined) | null) => {
        const key = primer ?
            function(x: any) {
                return primer(x[field])
            } :
            function(x: any) {
                return x[field]
            };

        const rev = !reverse ? 1 : -1;

        return function(a: any, b: any) {
            a = key(a); b = key(b);
            // @ts-ignore
            return rev * ((a > b) - (b > a));
        }
    }

    const sortedList = useCallback((sites: TrunkingSite[]) : TrunkingSite[] =>
        [...sites].sort(sort_by(currentSort.field, currentSort.order === 'desc',
            currentSort.field === 'nrOfCalls'? null :
                currentSort.field === 'type'? a => t(`trunking-sites.product-types.${a}`) : (a => !a ? "" :  a.toUpperCase())))
    , [currentSort, t]);

    const doSort = useCallback((sort: any) => {
        setCurrentSort(sort)
    }, [])

    const sorter = useCallback(col => sorterHeader(col, currentSort, doSort), [currentSort, doSort])

    const getRowKey = useCallback(site => site.uuid, [])

    return <>
        <TitledBody title={t('navigation.trunk-sites')}>
            <InfiniteScrollTable<TrunkingSite>
                hasNextPage={false}
                data={sortedList(me?.trunkingSites || [])}
                fetchNextPage={() => []}
                tableId={'trunkSitesTable'}
                isFetching={false}
                getRowKey={getRowKey}
                sorter={sorter}
                columns={columns}
            />
        </TitledBody>
    </>
}
