import React, {ReactElement, useCallback, useMemo} from 'react'
import {TFunction, useTranslation} from 'react-i18next'
import {AlternateNumber, BasicUser, CPE, Device, ExtensionValidationRule, Language, PhoneUserForm} from '../app/types'
import {useForm, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from '../common/form/TextInput'
import FormRow from '../common/form/FormRow'
import FormFieldBox from '../common/form/FormFieldBox'
import {useFormSubmission} from '../common/servercall/useFormSubmission'
import ErrorAlert from '../common/components/ErrorAlert'
import FormGroup from '../common/form/FormGroup'
import {find, propEq, sort, split} from 'ramda'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {
    allowedUserNcosSelector,
    callRecordingEnabledSelector,
    extensionValidationRulesSelector,
    fmuEnabledSelector,
    nrOfExtensionDigitsSelector,
    selectedSiteSelector
} from '../authentication/redux'
import {formatCPE, formatPhoneNumber, formatUserInDropdown} from '../common/util/formatters'
import {
    getAutocompleteAlternateNumbers,
    getAutocompleteOutgoingNumbers,
    getAutocompleteSiteAnnouncements,
    getAutocompleteSiteMsisdns,
    getAutocompleteSiteNumbers,
    getAutocompleteUsers
} from '../common/util/helpers'
import FormContainer from '../common/form/FormContainer'
import useTableInput, {moveItem, removeItem} from '../common/form/useTableInput'
import {ColumnDefinition} from '../common/components/table/Table'
import {Card, CardContent, CardHeader, Grid, IconButton, Paper, Tooltip} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import {SelectiveCallForwardForm, selectiveForwardSchema} from '../common/components/SelectiveCallForwardForm'
import {CallRecordingForm} from "./CallRecordingForm";
import FormCheckbox from "../common/form/FormCheckbox";
import AutocompleteWithAsyncFetch from "../common/form/AutocompleteWithAsyncFetch";
import {useWarningDialog} from "../common/components/warning/useWarningDialog";
import {useTableWithAdjustableOrder} from "../common/components/table/useTableWithAdjustableOrder";
import {usePhoneNumbersToForwardTo} from "../common/servercall/usePhoneNumbersToForwardTo";

export const emailRegex = /^$|^(([^<>()[\]\\.,;:\s@\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF"]+(\.[^<>()[\]\\.,;:\s@\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const extensionRegex = (nrOfExtensionDigits: number) => new RegExp(`^[0-9]{${nrOfExtensionDigits}}$`)

const schema = (t: TFunction, nrOfExtensionDigits: number, extensionValidationRules: ExtensionValidationRule[]) =>
    yup
        .object({
            firstName: yup.string().trim().max(30, 'too-long').required('required-m'),
            lastName: yup.string().trim().max(30, 'too-long').required('required-m'),
            webexEmail: yup
                .string()
                .when(['gbtfProfile'], {
                    is: (profile: string) => !['INTERNAL', 'BASIC', 'BASIC_INTERNAL'].includes(profile),
                    then: schema => yup.string().trim().max(70, 'too-long').required('required-m').matches(
                        emailRegex,
                        'invalid-email'
                    ),
                    otherwise: schema => yup.string().trim().max(70, 'too-long').matches(
                        emailRegex,
                        'invalid-email'
                    ).nullable(),
                }),
            language: yup.string().required('required-f'),
            gbtfProfile: yup.string().required('required-f'),
            extension: yup.lazy(value => {
                let schema = yup
                    .string()
                    .trim()
                    .required('required-f')
                    .matches(extensionRegex(nrOfExtensionDigits), t("general.form-error.invalid-extension", {nrOfExtensionDigits}))
                extensionValidationRules.forEach(rule => {
                    schema = schema.test({
                        name: 'test',
                        test: value => !value || !new RegExp(`^${rule.regex}$`).test(value),
                        message: rule.errorKey,
                    })
                })
                return schema.nullable()
            }),
            ncos: yup.string().required('required-f'),
            number: yup
                .string()
                .matches(/^$|^\+32[0-9]{8}$|^0[0-9]{8}$/, 'invalid-phone-number')
                .nullable(),
            selectiveCallForward: selectiveForwardSchema,
            outgoingNumber: yup
                .string()
                .matches(/^$|^\+32[0-9]{8}$|^0[0-9]{8}$/, 'invalid-phone-number')
                .nullable(),
            fmuMsisdn: yup
                .string()
                .when(['gbtfProfile'], {
                    is: (profile: string) => !['INTERNAL'].includes(profile),
                    then: schema =>  yup
                        .string()
                        .matches(/^$|^\+32[0-9]{9}$|^0[0-9]{9}$/, 'invalid-phone-number')
                        .nullable(),
                    otherwise: schema => yup.string().nullable(),
                }),
            cpeId: yup.string().nullable(),
            busyLampUsers: yup.array().nullable(true),
            blfNotificationDelayTime: yup.number().required('required-m').typeError('invalid-blf-notification-delay').min(0, 'invalid-blf-notification-delay').max(999, 'invalid-blf-notification-delay'),
            nonCpeDevices: yup.array().nullable(true),
            alternateNumbers: yup.array().max(10, 'alternate-numbers-max-10').nullable(),
        })
        .required()

type Props = {
    user: PhoneUserForm
    onSubmit: (formData: PhoneUserForm) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

export const allowedTools = [
    {
        value: `CONNECT_MOBILE`,
        label: 'users.allowed-tools-options.smartphone',
    },
    {
        value: `BUSINESS_COMMUNICATOR_PC`,
        label: 'users.allowed-tools-options.desktop',
    },
    {
        value: `CONNECT_TABLET`,
        label: 'users.allowed-tools-options.tablet',
    },
]

const calculateAllowedNcos = (profile: string, allowedUserNcos: string): string[] => {
    return sort(
        (a, b) =>
            a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base',
            }),
        split(',', allowedUserNcos || '')
    )
        .filter(ncos => (!(profile === 'BASIC_INTERNAL' && ncos !== 'NCOS36')))
}

export const UserForm = ({ user, onSubmit, readonly, buildFormButtons }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const allowedUserNcos = useSelector(allowedUserNcosSelector)
    const fmuEnabled = useSelector(fmuEnabledSelector)
    const callRecordingEnabled = useSelector(callRecordingEnabledSelector)
    const extensionValidationRules = useSelector(extensionValidationRulesSelector)
    const nrOfExtensionDigits = useSelector(nrOfExtensionDigitsSelector)
    const {phoneNumbersToForwardTo}= usePhoneNumbersToForwardTo(site)

    const { control, handleSubmit, setError, setValue, getFieldState } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema(t, nrOfExtensionDigits, extensionValidationRules)),
        defaultValues: user
    })

    const { submit: submitAfterWarning } = useFormSubmission<PhoneUserForm>(onSubmit, setError)

    const {warningDialog: WarningDialog, calls  } = useWarningDialog()

    const submitWithWarningCheck = useCallback(async (newUser: PhoneUserForm) => {
        if (!user.cpeId || user.cpeId === newUser.cpeId) {
            await onSubmit(newUser)
        } else {
            await calls(
                async () => {
                    if (!site) return []
                    const {data} = await api.get(`/api/site/${site.productId}/cpe/${user.cpeId}/check-user-assignment` )
                    return data
                },
                async () => {
                    await submitAfterWarning(newUser)
                }
            )
        }

        }, [calls, user, onSubmit, site, submitAfterWarning])

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<PhoneUserForm>(submitWithWarningCheck, setError)

    const cpeOptionParams = useMemo(() => {
        const defaultObject: Device | undefined = find(propEq('deviceType', 'CPE'), user.devices ? user.devices : [])

        return {
            getOptions: async () => {
                if (!site) return []
                const { data } = await api.get(`/api/site/${site.productId}/free-cpes`)
                const freeCpes = data as CPE[]
                return defaultObject ? [...freeCpes, { ...defaultObject, uuid: defaultObject.cpeId }] : freeCpes
            },
            getOptionLabel: (item: any) => formatCPE(item as Device),
            getOptionValue: (item: any) => (item as Device).uuid,
            isOptionEqualToValue: (option: any, value: any) => option.uuid === value.uuid,
            defaultObject: defaultObject ? { ...defaultObject, uuid: defaultObject.cpeId } : null,
            noOptionsText: t('users.edit.no-free-cpes-left'),
        }
    }, [user, site, t])

    const numberOptionParams = useMemo(() => {
        return getAutocompleteSiteNumbers(t, true, site, user?.number, user.alternateNumbers.map(an => an.number))
    }, [user, site, t])

    const outgoingNumberOptionParams = useMemo(() => {
        return getAutocompleteOutgoingNumbers(t, true, site, user?.outgoingNumber)
    }, [user, site, t])

    const msisdnOptionParams = useMemo(() => {
        return getAutocompleteSiteMsisdns(t, site, user?.fmuMsisdn)
    }, [user, site, t])

    const allowedToolsOptions = useMemo(
        () =>
            allowedTools.map(tool => {
                return {
                    value: tool.value,
                    label: t(tool.label),
                }
            }),
        [t]
    )

    const languageOptions = useMemo(
        () =>
            Object.values(Language).map(language => {
                return {
                    value: language,
                    label: t(`users.language.${language}`),
                }
            }),
        [t]
    )

    const profile = useWatch({
        control,
        name: 'gbtfProfile',
    })

    const ncosOptions = useMemo(
        () =>
            calculateAllowedNcos(profile, user.allowedUserNcos || allowedUserNcos || '')
                .map(ncos => ({ label: t(`users.ncos-names.${ncos}`), value: ncos })),
        [t, user, allowedUserNcos, profile]
    )

    const welcomeMessageOptionParams = useMemo(() => {
        return getAutocompleteSiteAnnouncements(t, 2000, site, user.welcomeMessageFileName ?? undefined)
    }, [user, site, t])

    const hidePhoneStatus = useWatch({
        control,
        name: 'hidePhoneStatus',
    })

    const alternateNumberColumns: (remove: removeItem, move: moveItem) => ColumnDefinition<AlternateNumber>[] = useCallback(
        (remove, move) => [
            {
                title: t('users.edit.alternate-number'),
                key: 'number',
                sorter: false,
                render: (text: string) => <>{formatPhoneNumber(text)}</>,
            },
            {
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: AlternateNumber, index) => (
                    <>
                        <Grid container>
                            <Tooltip placement={'right-end'} title={`${t('users.edit.busy-lamp-users.table.delete')}`}>
                                <IconButton
                                    size={'small'}
                                    aria-haspopup="true"
                                    onClick={() => {
                                        remove(index)
                                    }}>
                                    <DeleteIcon fontSize={'inherit'} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </>
                ),
            },
        ],
        [t]
    )

    const userOptionParams = useMemo(() => {
        return getAutocompleteUsers(
            t('flows.edit.no-users-found'),
            [user.uuid],
            false,
            site
        )
    }, [user, site, t])


    const { table: BusyLampUserTable, selector: BusyLampSelector } = useTableWithAdjustableOrder<BasicUser>('busyLampUsers', userOptionParams, control, formatUserInDropdown, user => user.uuid, t('users.edit.user-select-button'))

    const { table: AlternateNumbersTable, append: appendAlternateNumber, data: alternateNumbersData } = useTableInput('alternateNumbers', control, alternateNumberColumns)

    const { table: PermittedMonitorUsersTable, selector: PermittedMonitorSelector } = useTableWithAdjustableOrder<BasicUser>('permittedMonitorUsers', userOptionParams, control, formatUserInDropdown, user => user.uuid, t('users.edit.monitor-select-button'), true, t('users.edit.monitor'))

    const alternateNumberOptionParams = useMemo(() => {
        return {...getAutocompleteAlternateNumbers(t, true,
                [...user.alternateNumbers.map(nr => nr.number), ...(user.number ? [user.number] : [])],
                alternateNumbersData.map(nr => nr.number), site), disableClearable: true}
    }, [user, site, t, alternateNumbersData])


    return (
        <>
            <ErrorAlert showAlert={!!serverError} />
            <WarningDialog/>
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('users.edit.section.info')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} autofocus={true} label={t('users.edit.lastName')} name={'lastName'} control={control} required={true} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.firstName')} name={'firstName'} control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.email')} name={'webexEmail'} control={control} required={!['INTERNAL', 'BASIC', 'BASIC_INTERNAL'].includes(profile)} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.language')} name={'language'} control={control} required={true} options={languageOptions} />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput
                                readonly={readonly}
                                label={t('users.edit.license')}
                                name={'gbtfProfile'}
                                control={control}
                                required={true}
                                onChange={(e, node) =>
                                    {
                                        if (e.target.value === 'BASIC_INTERNAL') {
                                            setValue('ncos', 'NCOS36')
                                        } else if (profile === 'BASIC_INTERNAL') {
                                            setValue('ncos', calculateAllowedNcos(e.target.value, user.allowedUserNcos || allowedUserNcos || '')[0])
                                        }
                                    }
                                }
                                options={[
                                    { label: t('users.license.internal'), value: 'INTERNAL' },
                                    { label: t('users.license.basic_internal'), value: 'BASIC_INTERNAL' },
                                    { label: t('users.license.basic'), value: 'BASIC' },
                                    { label: t('users.license.standard'), value: 'STANDARD' },
                                    { label: t('users.license.premium'), value: 'PREMIUM' },
                                ]}
                            />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('users.edit.section.numbers')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.number')} name={'number'} control={control} required={false} autoCompleteOptions={numberOptionParams} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput label={t('users.edit.extension')} name={'extension'} control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput
                                readonly={readonly}
                                label={t('users.edit.outgoing-number')}
                                name={'outgoingNumber'}
                                control={control}
                                required={false}
                                autoCompleteOptions={outgoingNumberOptionParams}
                            />
                        </FormFieldBox>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.allow-change-outgoing-number')}
                                name={'allowChangeOutgoingCli'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                    {fmuEnabled && profile !== 'INTERNAL' && (
                        <FormRow>
                            <FormFieldBox>
                                <TextInput readonly={readonly} label={t('users.edit.fmu-msisdn')} name={'fmuMsisdn'} control={control} required={false} autoCompleteOptions={msisdnOptionParams} />
                            </FormFieldBox>
                        </FormRow>
                    )}
                    <FormRow>
                        <FormFieldBox mdSize={8} lgSize={6}>
                            <AutocompleteWithAsyncFetch
                                keepEmpty={true}
                                preventDefaultEnterBehavior={true}
                                help={t('users.edit.alternate-numbers-helper-text')}
                                label={t('users.edit.alternate-numbers')}
                                name={'test2'}
                                autoCompleteOptions={alternateNumberOptionParams}
                                invalid={false}
                                onChange={value => {
                                    value && appendAlternateNumber({number: value} )
                                }}
                            />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={12}>
                            <Paper elevation={2}>
                                <AlternateNumbersTable />
                            </Paper>
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('users.edit.section.privacy')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.hide-from-phonebook')}
                                name={'hideFromPhoneBook'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.hide-phone-status')}
                                name={'hidePhoneStatus'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                    {hidePhoneStatus &&
                        <>
                            <FormRow>
                                <FormFieldBox  mdSize={6}>
                                    {PermittedMonitorSelector}
                                </FormFieldBox>
                            </FormRow>
                            <FormRow>
                                <FormFieldBox mdSize={12}>
                                    <Paper elevation={2}>
                                        <PermittedMonitorUsersTable />
                                    </Paper>
                                </FormFieldBox>
                            </FormRow>
                        </>
                    }
                </FormGroup>
                <FormGroup label={t('users.edit.section.services')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.ncos')} name={'ncos'} control={control} required={true} options={ncosOptions} />
                        </FormFieldBox>
                        {profile !== 'INTERNAL' &&
                            <FormFieldBox>
                                <TextInput
                                    help={t('general.max-file-size', {size: 2})}
                                    readonly={readonly}
                                    label={t('flows.edit.welcome-message')}
                                    name={'welcomeMessageFileName'}
                                    control={control}
                                    required={false}
                                    autoCompleteOptions={welcomeMessageOptionParams}
                                />
                            </FormFieldBox>
                        }
                        {profile !== 'INTERNAL' &&
                            <FormFieldBox>
                                <FormCheckbox
                                    label={t('users.edit.prio-external-calls')}
                                    name={'prioExternalCalls'}
                                    control={control}
                                    setValue={setValue}
                                    getFieldState={getFieldState}
                                />
                            </FormFieldBox>
                        }
                        {profile !== 'INTERNAL' &&
                            <FormFieldBox>
                                <FormCheckbox
                                    label={t('users.edit.optimized-for-teams')}
                                    name={'optimizedForTeams'}
                                    control={control}
                                    setValue={setValue}
                                    getFieldState={getFieldState}
                                />
                            </FormFieldBox>
                        }
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('users.edit.section.cpe')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.cpe')} name={'cpeId'} control={control} required={false} autoCompleteOptions={cpeOptionParams} />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        {profile !== 'INTERNAL' &&
                            <FormFieldBox>
                                <TextInput
                                    readonly={readonly}
                                    label={t('users.edit.allowed-tools')}
                                    name={'nonCpeDevices'}
                                    control={control}
                                    required={false}
                                    options={allowedToolsOptions}
                                    isCheckbox={true}
                                />
                            </FormFieldBox>
                        }
                    </FormRow>
                </FormGroup>
                <SelectiveCallForwardForm control={control} selectiveCallForward={user.selectiveCallForward} fullWidth={true} phoneNumbersToForwardTo={phoneNumbersToForwardTo}/>
                {callRecordingEnabled && profile !== 'INTERNAL' &&
                    <CallRecordingForm control={control} setValue={setValue} getFieldState={getFieldState}/>
                }
                <FormGroup label={t('users.edit.section.busy-lamp-field')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox  mdSize={6}>
                            {BusyLampSelector}
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={12}>
                            <Paper elevation={2}>
                                <BusyLampUserTable />
                            </Paper>
                        </FormFieldBox>
                    </FormRow>
                    <Grid container mt={2}>
                        <Grid item xs={12} >
                            <Card variant="outlined">
                                <CardHeader subheader={t('users.edit.blf-in-multi-call-window')}/>
                                <CardContent>
                                    <FormRow spacing={5}>
                                        <FormFieldBox>
                                            <FormCheckbox
                                                label={t('users.edit.blf-hide-caller-id')}
                                                name={'blfHideCallerId'}
                                                control={control}
                                                setValue={setValue}
                                                getFieldState={getFieldState}
                                            />
                                        </FormFieldBox>
                                        <FormFieldBox>
                                            <TextInput readonly={readonly} label={t('users.edit.blf-time-before-pickup-notification')} name={'blfNotificationDelayTime'} control={control} required={true} />
                                        </FormFieldBox>
                                    </FormRow>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </FormGroup>
                {!readonly && buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
    )
}
