import React, {useCallback, useMemo} from 'react'
import {TitledBody} from '../common/components/TitledBody'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'
import {Sort, useInfiniteScroll} from "../common/components/table/useInfiniteScroll";
import {EnterpriseGbtfSite, NumberRange, ServerPage, Site} from "../app/types";
import {api, buildPaginatedUrl} from "../common/api";
import {meSelector, sitesSelector} from "../authentication/redux";
import {useSelector} from "react-redux";
import {ColumnDefinition, InfiniteScrollTable} from "../common/components/table/Table";
import {Link, List, ListItem, Typography} from "@mui/material";
import {sorterHeader} from "../common/components/table/SorterHeader";
import ErrorAlert from "../common/components/ErrorAlert";
import {always, any, ifElse, isEmpty} from "ramda";
import {Box, Flex} from "rebass";
import {MoreHoriz} from "@mui/icons-material";
import ClickableTooltip from "../common/components/ClickableTooltip";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import {formatPhoneNumber, formatTotalStat} from "../common/util/formatters";

const formatRange = (nr: NumberRange) => `${formatPhoneNumber(nr.startNumber)} - ${formatPhoneNumber(nr.stopNumber)}`

const formatRangeArray = (ranges: NumberRange[]) => ifElse(isEmpty, always(""), ranges => formatRange(ranges[0]))(ranges)

const formatAllFromRangeArray = (ranges: NumberRange[]) =>
    <List dense={true}>
        {ranges.map(rn => <ListItem><ListItemText primary={formatRange(rn)}/></ListItem>)}
    </List>

export const VoiceCloudSites = () => {
    const { t } = useTranslation()
    const me = useSelector(meSelector)
    const history = useHistory()
    const availableSites = useSelector(sitesSelector)

    const hasAccessToSite = useCallback((siteProductId: string) =>
        availableSites && any((s: Site | null) => !!s && s.productId === siteProductId, availableSites), [availableSites])

    const fetchSites = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<EnterpriseGbtfSite>> => {
            if (!me) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/enterprise/${me.voiceCloudProductId}/sites`, pageParam, sortParam, searchTermParam))
            return data
        },
        [me]
    )

    const { sort, setSort, rows, error, fetchNextPage, hasNextPage, isFetching } = useInfiniteScroll<EnterpriseGbtfSite>({
        queryKey: `enterpriseSites-${me?.voiceCloudProductId}`,
        initialSort: { field: 'productId', order: 'asc' },
        fetchFunction: fetchSites,
    })

    const goToSite = useCallback(async (e, siteId) => {
        e.preventDefault()
        history.push(`/switch-site?to-site=${siteId}&from-site=${''}&to-url=${"/site"}&from-url=${history.location.pathname}`)
        return false;
    }, [history])

    const columns: ColumnDefinition<EnterpriseGbtfSite>[] = useMemo(
        () => [
            {
                title: t('trunking-sites.table.product-id'),
                key: 'productId',
                sorter: true,
                render: (text: string, record) => hasAccessToSite(record.productId) ?
                    <Link href={'#'} onClick={(e) => goToSite(e, record.productId)}>{text}</Link> : <>{text}</>,
            },
            {
                title: t('enterprise-sites.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string, record) => <>{text}</>,
            },
            {
                title: t('site.cards.licenses.table.license'),
                key: 'license',
                sorter: false,
                render: (text: string, record)=> <>{t(`general.license.${text.toLowerCase()}`)}</>,
            },
            {
                title: t('site.cards.licenses.title'),
                key: 'licenses',
                sorter: false,
                render: (text: string, record)=> <>{formatTotalStat(record.licensesUsed, record.licenses)}</>,
            },
            {
                title: t('navigation.cpe'),
                key: 'cpes',
                sorter: false,
                render: (text: string, record) => <>{formatTotalStat(record.cpesUsed, record.cpes)}</>,
            },
            {
                title: t('enterprise-sites.table.hunt-groups'),
                key: 'huntGroups',
                sorter: false,
                render: (text: string, record) => <Typography sx={{ fontSize: 14 }}>{record.huntGroups}</Typography>,
            },
            {
                title: t('enterprise-sites.table.queues'),
                key: 'queues',
                sorter: false,
                render: (text: string, record) => <Typography color={record.maxNrOfCallQueues !== -1 && record.queues > record.maxNrOfCallQueues ? 'error.main' : 'text.secondary'} sx={{ fontSize: 14 }}>{ formatTotalStat(record.queues, record.maxNrOfCallQueues)}</Typography>,
            },
            {
                title: t('navigation.ivrs'),
                key: 'ivrs',
                sorter: false,
                render: (text: string, record) => <Typography color={record.maxNrOfIvrs !== -1 && record.ivrs > record.maxNrOfIvrs ? 'error.main' : 'text.secondary'} sx={{ fontSize: 14 }}>{formatTotalStat(record.ivrs, record.maxNrOfIvrs)}</Typography>,
            },
            {
                title: t('enterprise-sites.table.number-ranges'),
                key: 'numberRanges',
                sorter: false,
                render: (text: string, record) =>
                    <Flex justifyContent={"flex-start"} alignItems={"center"} >
                        <Box ><span style={{whiteSpace: "nowrap"}}>{formatRangeArray(record.numberRanges)}</span></Box>
                        {record.numberRanges.length > 1 ? <Box><ClickableTooltip title={formatAllFromRangeArray(record.numberRanges)} buttonFn={(onClick) => <IconButton size="small" color="secondary" aria-label="df" component="span" onClick={onClick}>
                            <MoreHoriz/>
                        </IconButton>}/></Box> : <></>}
                    </Flex>,
            }
            ,
        ],
        [t, goToSite, hasAccessToSite]
    )

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(cpe => cpe.uuid, [])

    return <>
        <TitledBody title={t('navigation.voicecloud-sites')}>
            <ErrorAlert showAlert={!!error} />
            <InfiniteScrollTable<EnterpriseGbtfSite>
                hasNextPage={hasNextPage}
                data={rows}
                fetchNextPage={fetchNextPage}
                tableId={'enterpriseSitesTable'}
                isFetching={isFetching}
                getRowKey={getRowKey}
                sorter={sorter}
                columns={columns}
            />
        </TitledBody>
    </>
}
