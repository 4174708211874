import React, {ReactElement, useCallback} from 'react'
import FormButtons from "./FormButtons";
import SpinnableSubmitButton from "./SpinnableSubmitButton";
import {useTranslation} from "react-i18next";
import {Grid, Paper} from "@mui/material";
import StickyBox from "../components/StickyBox";

const buttonPadding = '0.50rem'

export const useFormSubmissionButtons = (isCreation: boolean) => {
    const { t } = useTranslation()

    const buildFormButtons = useCallback( (cancelEditButton: ReactElement) => (isSubmitting: boolean) =>
        isCreation ?
            <FormButtons buttons={[<SpinnableSubmitButton label={t('general.form-create')} showSpinner={isSubmitting} />, cancelEditButton]} fullWidth={true}/>
            :
            //zindex of mui dialog is 1300, so we need to be below that
            <StickyBox offsetTop={20} bottom style={{zIndex: 1200}}>
                <Grid item >
                    <Paper variant={'elevation'} sx={{padding: buttonPadding }}>
                        <FormButtons buttons={[<SpinnableSubmitButton label={t('general.form-update')} showSpinner={isSubmitting} />, cancelEditButton]} fullWidth={true}/>
                    </Paper>
                </Grid>
            </StickyBox>
        , [t, isCreation])

    return {buildFormButtons}
}
