// @ts-ignore
// @ts-ignore

import StringSchema from "yup/lib/string";
import {AnyObject} from "yup/es/types";
import {CustomUserClaims} from "@okta/okta-auth-js";
import {Option} from "../common/form/TextInput";

declare global {
    interface Window {
        issuerUrl: string
        clientId: string
    }
}

export interface OktaClaims extends CustomUserClaims{
    language: string
}

export type User = {
    username: string
    language: string
    privileges: Privilege[]
    customerName: string,
    voiceCloudProductId: string | null
    profile: string,
    gbtfSites: Site[]
    trunkingSites: TrunkingSite[]
    enterpriseAdmin: boolean
}
export type Privilege = 'ENTITY_READ' | 'ENTITY_UPDATE'

export type PhoneNumber = {
    uuid: string
    number: string
    assignedTo: string
    type: string
    extension: string
    fmuMsisdn: string
}

export type Site = {
    name: string
    productId: string
    license: string
}

export enum Language {
    nl = 'NL',
    fr = 'FR',
    en = 'EN',
}

export interface BasicUser {
    uuid: string
    firstName: string
    lastName: string
    number: string | null
    language: Language
}

export interface PhoneUser extends BasicUser {
    outgoingNumber: string | null
    webexEmail: string
    gbtfProfile: string
    ncos: string
    extension: string
    busyLampUsers: BusyLampUser[]
    devices: Device[]
    allowedUserNcos?: string
    fmuMsisdn: string | null
    fmuMode: string | null
    callForwarding?: CallForwarding
    selectiveCallForward: SelectiveCallForward | null
    callRecordingUserSettings: CallRecordingUserSettings | null
    businessId?: string
    callStatus?: string | null
    blfHideCallerId: boolean
    blfNotificationDelayTime: number
    prioExternalCalls: boolean
    optimizedForTeams: boolean
    alternateNumbers: AlternateNumber[]
    welcomeMessageFileName: string | null
    eligibleForHotDesking?: boolean
    hideFromPhoneBook: boolean
    hidePhoneStatus: boolean
    permittedMonitorUsers: PermittedMonitorUser[]
    allowChangeOutgoingCli: boolean
}

export interface AlternateNumber {
    uuid?: string //--not existing
    number: string
}

export interface UserQueueAvailability {
    queueId: string
    queueName: string
    available: boolean
}

export interface CallForwarding {
    fetchingErrorOccured: boolean
    forwardingAlwaysActive: boolean
    forwardingAlwaysNumber: string
    forwardingBusyActive: boolean
    forwardingBusyNumber: string
    forwardingNoAnswerActive: boolean
    forwardingNoAnswerNumber: string
    forwardingNoAnswerNumberOfRings: number
    forwardingNotReachableActive: boolean
    forwardingNotReachableNumber: string
}

export interface OutOfOffice {
    presence: string,
    expirationTime: string | null
    expirationTimeAsDate: Date | null
    enableTransferToAttendant: boolean
    attendantNumber: string
    enableRingSplash: boolean
}

export interface SequentialRingLocation {
    phoneNumber: string
    numberOfRings: number
    answerConfirmationRequired: boolean
}

export interface SequentialRingCriteriaActivation {
    name: string
    activated: boolean
}

export interface SequentialRing {
    ringBaseLocationFirst: boolean,
    baseLocationNumberOfRings: number
    continueIfBaseLocationIsBusy: boolean
    callerMayStopSearch: boolean
    locations: SequentialRingLocation[]
    criteriaActivations: SequentialRingCriteriaActivation[]
    activeCriteria: string[]
}

export interface UserIncomingCalls {
    doNotDisturbEnabled: boolean
    anonymousCallRejectionEnabled: boolean
    callWaitingEnabled: boolean
    outOfOffice: OutOfOffice
    callForwarding: CallForwarding
    sequentialRingNumbers: SequentialRing
}

export interface UserOutgoingCalls {
    blockCallerIdActive: boolean
    callingLineIdPolicy: string
}

export interface UserCallControl {
    alertAllLocations: boolean
    callCenterAvailabilities: CallCenterAvailability[]
    phoneNumberAnywhereList: PhoneNumberAnywhere[]
}

export interface PhoneNumberAnywhere {
    number: string
    active: boolean
    callControl: boolean
    useDiversionInhibitor: boolean
    answerConfirmationRequired: boolean
}

export interface CallCenterAvailability {
    callCenterId: string
    name: string
    available: boolean,
}

export interface VoicemailSettings {
    active: boolean
    fetchingErrorOccured: boolean
    alwaysRedirectToVoiceMail: boolean
    busyRedirectToVoiceMail: boolean
    noAnswerRedirectToVoiceMail: boolean
    noAnswerNumberOfRings: number
    processing: string
    usePhoneMessageWaitingIndicator: boolean
    deliveryEmailAddress: string | null
    notifyEmail: boolean
    notifyEmailAddress: string | null
    sendCarbonCopyVoiceMessage: boolean
    carbonCopyEmailAddress: string | null
    transferOnZeroToPhoneNumber: boolean
    transferPhoneNumber: string | null
    firstName?: string
    lastName?: string
}

export interface PhoneUserForm extends PhoneUser {
    nonCpeDevices?: (string | null)[]
    cpeId?: string | null
}

export interface BusyLampUser extends BasicUser {
    siteProductId: string
    userBusinessId: string
}

export interface PermittedMonitorUser extends BasicUser {
    siteProductId: string
}

export type Device = {
    uuid: string
    deviceType: 'CPE' | 'BUSINESS_COMMUNICATOR_PC' | 'CONNECT_MOBILE' | 'CONNECT_TABLET'
    cpeId: string | null
    model: string | null
    macAddress: string | null
    identifier: string | null
    name ?: string | null
    extension: string | null
}

export type CPE = {
    uuid: string
    model: string | null
    macAddress: string | null
    name: string | null
    ipei: string | null
    identifier: string | null
    userFirstName: string | null
    userLastName: string | null
    userWebexEmail: string | null
    userUuid: string | null
    eligibleForActivationCode: boolean
    hotDeskExtension: string | null
}

export type EnterpriseGbtfSite = {
    productId: string
    name: string
    license: string
    licenses: number
    licensesUsed: number
    cpes: number
    cpesUsed: number
    huntGroups: number
    ivrs: number
    maxNrOfCallQueues: number
    maxNrOfIvrs: number
    queues: number
    numberRanges: NumberRange[]
}

export type TrunkingSite = {
    name: string
    productId: string
    type: string
    nrOfCalls: number
    numberRanges: NumberRange[]
}

export type NumberRange = {
    startNumber: string
    stopNumber: string
}

export type CpeConfigResult = {
    cpeSettings: CPESettings;
    hotDeskSettings: HotDeskBasicSettings | null;
}

export type CPESettings = {
    model?: string
    features: CpeFeature[],
    keys: CpeKey[]
}

export type HotDeskBasicSettings = {
    hotDeskingEnabled: boolean
    hotDeskAssociationLimitEnabled: boolean
    hotDeskAssociationLimitHours: number
}

export interface HotDeskSettings extends HotDeskBasicSettings {
    cpeSettings : CPESettings | null;
}

export type CpeFeature = {
    type: string,
    value: string | null
}

export type CpeKey = {
    "type": string | null,
    "key": string,
    "value": string | null,
    "label": string | null,
    "editable"?: boolean
}

export type KeyConfigForm = {
    [K in 'mainPhone' | 'kem1' | 'kem2' | 'kem3']: KemForm
}

export interface CpeMPPConfigForm extends Pick<KeyConfigForm, "mainPhone" | "kem1" | "kem2" | "kem3"> {
    autoFillBlf: boolean
    hotDeskSettings: HotDeskBasicSettings
}

export type CpeYealinkKeyConfigForm = Pick<KeyConfigForm, "mainPhone">;

export type KemForm = {
    keys: CpeKey[]
}

export type CpeAtaConfigForm = {
    type: string
    hotlineNumber:string
    numberTranslations: AtaNumberTranslation[]
}

export type AtaNumberTranslation = {
    from: string
    to: string
}

export type CpeFeatureMetaData = {
    type: string
    validationRule: () => StringSchema<string | null | undefined, AnyObject, string | null | undefined>
    required: boolean
    fieldType: 'CHECKBOX' | 'TEXT' | 'PHONENUMBER'
}

export interface CPEKeyTypeMetaData extends Option {
    needsArg: boolean
    validationRule: () => StringSchema<string | null | undefined, AnyObject, string | null | undefined>,
    onlyReadOnly?: boolean
    needsNoLabel?: boolean
}

export interface AnswerGroup {
    answerGroupType: 'QUEUE' | 'HUNT_GROUP'
    uuid: string
    name: string
    language: Language
    extension: string
    number: string
    ncos: string
    userAgents: AnswerGroupAgent[]
    overflowDestination: string
    selectiveCallForward: SelectiveCallForward | null
    allowAgentLogoff: boolean | null
    allowCallWaitingForAgents: boolean | null
    voicemailEnabled: boolean
    enableGroupBusy?: boolean | null
    allowMembersToControlGroupBusy?: boolean | null
    voicemailUsage: string
    nightServiceMode: string
    prioExternalCalls: boolean
    playRingingWhenOfferingCall: boolean
}

export interface AnswerGroupAgent extends BasicUser {
    available: boolean
    userBusinessId: string
    siteProductId: string
}

export interface AnswerGroupForm extends AnswerGroup {
    huntPolicy: string
    voicemailEmail: string | null
    additionalVoicemailEmails: string | null
    agentRingTime: number
    overflowNumber: string | null
    welcomeMessageFileName: string | null
    musicOnHoldFileName: string | null
    comfortMessageFileName: string | null
    overflowAnnouncementFileName: string | null
    comfortMessageGapTime: number
    webexLogin: boolean
    queueLength: number | null
    forwardTimeoutSeconds: number | null
    overflowTimeout: number | null
    voicemailNoAnswerAnnouncement: string | null
    callForwardAnnouncement: string | null
}

export interface SelectiveCallForward {
    outsideOpeningHoursPhoneNumber: string | null
    holidayPhoneNumber: string | null
    outsideOpeningHoursScheduleUuid: string | null
    outsideOpeningHoursSchedule?: Schedule | null
    holidayScheduleUuid: string | null
    holidaySchedule?: Schedule | null
}

export interface CallRecordingUserSettings {
    callRecordingMode: string;
    playAnnouncement: boolean;
    recordVoiceMessaging: boolean;
}

export interface IvrKeyAction {
    menuKey: string
    description: string | null
    gbtfIvrSubMenuAction: string | null
    config: any
    dependencyInfo?: any
}

export interface IvrKeyActionMap {
    [key: string]: IvrKeyAction
}

export interface IvrSubMenuBasic {
    id: string
    announcementFileName: string | null
    businessHoursRoot: boolean
}

export interface IvrSubMenu extends IvrSubMenuBasic {
    keyActions: IvrKeyActionMap
}

export interface IvrSubMenuFormFriendly extends IvrSubMenuBasic {
    keyActions: IvrKeyAction[]
}

export interface IvrSubMenuMap {
    [key: string]: IvrSubMenu
}

export interface IvrMenuConfig {
    subMenus: IvrSubMenuMap
}

export interface Ivr {
    uuid: string
    name: string
    language: Language
    extension: string
    number: string
    ncos: string
    menuConfig: IvrMenuConfig
    selectiveCallForward: SelectiveCallForward | null
    operator: IvrOperator
    transferToOperatorAfter: number | null
    alternateNumbers: AlternateNumber[]
}

export interface IvrOperator {
    type: string,
    user: IvrOperatorUser | null
    answerGroup: IvrOperatorAnswerGroup | null
}

export interface IvrOperatorUser {
    uuid: string
    firstName: string
    lastName: string
}

export interface IvrOperatorAnswerGroup {
    uuid: string
    name: string
}

export interface IvrForm extends Ivr {
    operatorUserId: string | null
    operatorAnswerGroupId: string | null
    operatorType: string
}

export interface IvrNodeData {
    label: string
    final: boolean
    hasParent: boolean
    ivrSubMenu?: IvrSubMenu
    ivrKeyAction?: IvrKeyAction
    validationErrors?: string[]
    readonly: boolean
}

export interface ScheduleTimeSlot {
    hour: number
    minute: number
}

export interface ScheduleEvent {
    name: string
    startDate: string
    endDate: string | null
    startTime: ScheduleTimeSlot | null
    endTime: ScheduleTimeSlot | null
    allDayEvent: boolean
    scheduleDay: string | null
}

export interface ScheduleConfig {
    eventList: ScheduleEvent[]
}

export interface Schedule {
    uuid: string
    name: string
    type: string
    eventConfig?: ScheduleConfig
    configJson?: ScheduleConfig
}

export interface HolidayForm extends Schedule {
    holidayPeriods: HolidayPeriod[]
}

export type HolidayPeriod = {
    eventName: string
    from: Date | null
    to: Date | null
    allDay: boolean
    startTime: string | null
    endTime: string | null
}

export interface OpeningHoursForm extends Schedule {
    days: Days
}

export enum Day {
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
}

export type Days = {
    [day: string]: OpeningHourEntries
}

export type OpeningHourEntries = {
    entries: OpeningHourEntry[]
}

export type OpeningHourEntry = {
    startTime: string
    endTime: string
}

export type PhoneBookEntry = {
    uuid: string
    name: string
    number: string
}

export type PhoneBookConfig = {
    externalPhoneBookName: string
    internalPhoneBookName: string
    internalEnterprisePhoneBookEnabled: boolean
}

export type Announcement = {
    name: string
    fileSize: number
    mediaType: string
}

export type LicenceInfo = {
    name: string
    total: number
    taken: number
}

export type UserOption = {
    type: string
    usedLicenses: string
    nrOfLicenses: string
}

export type CPEInfo = {
    model: string
    total: number
    taken: number
}

export type ExtensionValidationRule = {
    regex: string
    errorKey: string
}

export type CallPickupGroup = {
    uuid: string
    name: string
    users: CallPickupUser[]
}
export interface CallPickupUser extends BasicUser {}

export type Paging = {
    uuid: string
    name: string
    extension: string
    originatorUsers: PagingUser[]
    targetUsers: PagingUser[]
}

export interface PagingUser extends BasicUser {}

export type FAC = {
    name: string
    mainCode: string | null
}

export type EmergencyCallNotification = {
    notificationEnabled: boolean
    notificationEmail: string
}

export type NightService = {
    enabled: boolean
    forwardNumber: string
}

export type Executive = {
    id: string | null
    userId: string | null
    userFirstName: string | null
    userLastName: string | null
    assistants: ExecutiveAssistant[]
}

export type ExecutiveAssistant = {
    userId: string
    userBusinessId: string
    siteProductId: string
    userFirstName: string
    userLastName: string
}

export type ExecutiveAssistantSetting = {
    executiveUserId: string
    userId: string
    enableDivert: boolean
    divertNumber: string
    optIn: boolean
}

export type ExecutiveWithAssistantsForm = {
    executiveUser: ExecutiveUser | null
    assistantUsers: AssistantUser[]
}

export type ExecutiveFiltering = {
    enabled: boolean
    filterMode: string
    simpleFilterType: string;
    excludedNumbers: string[];
}

export type ExecutiveScreeningAlert = {
    alertingMode: string
    ringTimePerAssistant: number
    callPushRecallRingTime: number
    enableRollover: boolean
    rolloverWaitTimeSeconds: number
    rolloverAction: string
    rolloverForwardNumber: string
}

export interface AssistantUser extends BasicUser {}
export interface ExecutiveUser extends BasicUser {}

export type PortalConfig = {
    nrOfExtensionDigits: number
    extensionValidationRules: ExtensionValidationRule[]
    allowedUserNcos: string
    defaultNcos: string
    fmuEnabled: boolean
    maxNrOfCallQueues: number
    maxNrOfIvrs: number
    callRecordingEnabled: boolean
    executiveAssistantEnabled: boolean
    hotDeskEnabled: boolean
    pagingEnabled: boolean
    callPickupEnabled: boolean
    announcement: PortalAnnouncement | null
    siteOptions: UserOption[]
}

export type PortalAnnouncement = {
    messageNl: string
    messageFr: string
    messageEn: string
}

export type ServerPage<T> = {
    content: T[]
    number: number
    totalPages: number
    totalElements: number
}

export type BlackListedNumber = {
    startNumber: string,
    stopNumber: string
}
