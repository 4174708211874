import {useCallback, useState} from 'react'
import {FileError, FileRejection, useDropzone} from 'react-dropzone'
import {Box, CardContent, Container, Grid, Typography} from '@mui/material'
import {styled} from '@mui/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {FileUploadWithProgress} from './FileUploadWithProgress'

const DropZone = styled('div')(({ theme }) => ({
    border: `2px dashed`,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    cursor: 'pointer',
    color: 'darkgrey',
}))

export interface UploadableFile {
    file: File
    errors: FileError[]
}

type props = {
    uploadUrl: string
    dropzoneTitle: string
    onFileUploaded: (file: File) => void
    acceptMime: string | string []
    maxSizeInBytes: number,
    showSuccess?: boolean
}

const FileDropZone = ({
                          uploadUrl,
                          onFileUploaded,
                          acceptMime,
                          maxSizeInBytes,
                          dropzoneTitle,
                          showSuccess = false,
                      }: props) => {
    const [files, setFiles] = useState<UploadableFile[]>([])
    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        const mappedAcc = acceptedFiles.map(acceptedFile => ({ file: acceptedFile, errors: [] }))
        setFiles(curr => [...curr, ...mappedAcc, ...rejectedFiles])
    }, [])

    const deleteFile = useCallback(file => {
        setFiles(currFiles => currFiles.filter(fw => fw.file !== file))
    }, [])

    const onSuccess = useCallback(
        file => {
            onFileUploaded(file)
            !showSuccess && deleteFile(file)
        },
        [onFileUploaded, deleteFile, showSuccess],
    )

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: acceptMime, maxSize: maxSizeInBytes })
    const { ref, ...rootProps } = getRootProps()

    return (
        <div ref={ref}>
            <Container maxWidth={'md'}>
                <Grid container spacing={1} direction={'column'} p={1} mb={2}>
                    <Grid item>
                        <DropZone {...rootProps}>
                            <input {...getInputProps()} />

                            <CardContent>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography gutterBottom variant='h6' color='text.secondary'>
                                        {dropzoneTitle}
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <CloudUploadIcon />
                                    </Box>
                                </Box>
                            </CardContent>
                        </DropZone>
                    </Grid>
                </Grid>
                <Grid item mb={files.length ? 4 : 0} p={1}>
                    <Grid container spacing={2}>
                        {files.map((fileWrapper, idx) => (
                            <FileUploadWithProgress
                                onSuccess={onSuccess}
                                onDelete={deleteFile}
                                key={fileWrapper.file.name}
                                frontErrorCode={fileWrapper.errors.length ? fileWrapper.errors[0].code : undefined}
                                uploadUrl={uploadUrl}
                                file={fileWrapper.file}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default FileDropZone
