import React, {ReactElement} from 'react'
import {TFunction, useTranslation} from 'react-i18next'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {ExtensionValidationRule, Paging} from '../../app/types'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormContainer from '../../common/form/FormContainer'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import TextInput from '../../common/form/TextInput'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import FormGroup from '../../common/form/FormGroup'
import PagingUserInputTable from './PagingUserInputTable'
import {useSelector} from 'react-redux'
import {extensionValidationRulesSelector, nrOfExtensionDigitsSelector} from '../../authentication/redux'

const schema = (t: TFunction, nrOfExtensionDigits: number, extensionValidationRules: ExtensionValidationRule[]) =>
    yup
        .object()
        .shape({
            name: yup
                .string()
                .trim()
                .max(30, 'too-long')
                .required('required-m')
                .matches(/^[^\\/]*$/, 'invalid-schedule-name'),
            extension: yup.lazy(value => {
                let schema = yup
                    .string()
                    .trim()
                    .matches(new RegExp(`^$|^[0-9]{${nrOfExtensionDigits}}$`), {
                        message : t("general.form-error.invalid-extension", {nrOfExtensionDigits}),
                        excludeEmptyString: true,
                    })
                extensionValidationRules.forEach(rule => {
                    schema = schema.test({
                        name: 'test',
                        test: value => !value || !new RegExp(`^${rule.regex}$`).test(value),
                        message: rule.errorKey,
                    })
                })
                return schema.nullable()
            }),
            originatorUsers: yup.array().min(1, 'user-min-1-required'),
            targetUsers: yup.array().min(1, 'user-min-1-required'),
        })
        .required()

type Props = {
    paging: Paging
    onSubmit: (formData: Paging) => void
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

const PagingFormScreen = ({ paging, onSubmit, buildFormButtons }: Props) => {
    const { t } = useTranslation()
    const nrOfExtensionDigits = useSelector(nrOfExtensionDigitsSelector)
    const extensionValidationRules = useSelector(extensionValidationRulesSelector)

    const { control, handleSubmit, setError, clearErrors } = useForm<Paging>({
        resolver: yupResolver<yup.AnyObjectSchema>(schema(t, nrOfExtensionDigits, extensionValidationRules)),
        defaultValues: {
            ...paging,
        },
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<Paging>(onSubmit, setError)

    return (
        // @ts-ignore
        <FormProvider clearErrors={clearErrors}>
            <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('pagings.edit.section.info')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput autofocus={true} label={t('pagings.edit.name')} name={'name'} control={control} required={true} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput label={t('flows.edit.extension')} name={'extension'} control={control} required={false} />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('pagings.edit.section.originator-users')} fullWidth={true}>
                    <FormRow>
                        <PagingUserInputTable fieldName={'originatorUsers'} clearErrors={clearErrors} control={control} />
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('pagings.edit.section.target-users')} fullWidth={true}>
                    <FormRow>
                        <PagingUserInputTable fieldName={'targetUsers'} clearErrors={clearErrors} control={control} />
                    </FormRow>
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </FormProvider>
    )
}

export default PagingFormScreen
