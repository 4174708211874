import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {Language, PhoneUserForm} from '../app/types'
import {useSelector} from 'react-redux'
import {defaultNcosSelector, selectedSiteSelector} from '../authentication/redux'
import {UserForm} from './UserForm'
import {api} from '../common/api'
import {useHistory} from 'react-router'
import {defaultCallRecording} from "./detail";
import CancelButton from "../common/form/CancelButton";
import {Grid} from "@mui/material";
import {useFormSubmissionButtons} from "../common/form/useFormSubmissmionButtons";

const initialUser = (defaultNcos: string) => ({
    uuid: '',
    firstName: '',
    lastName: '',
    number: '',
    outgoingNumber: '',
    webexEmail: '',
    gbtfProfile: 'BASIC',
    ncos: defaultNcos,
    extension: '',
    devices: [],
    nonCpeDevices: ['CONNECT_MOBILE', 'BUSINESS_COMMUNICATOR_PC'],
    busyLampUsers: [],
    permittedMonitorUsers: [],
    blfHideCallerId: false,
    blfNotificationDelayTime: 12,
    language: Language.nl,
    fmuMsisdn: '',
    fmuMode: 'OFF',
    voicemailEnabled: false,
    prioExternalCalls: true,
    optimizedForTeams: false,
    selectiveCallForward: {
        outsideOpeningHoursScheduleUuid: '',
        holidayPhoneNumber: '',
        holidayScheduleUuid: '',
        outsideOpeningHoursPhoneNumber: '',
    },
    callRecordingUserSettings : {
        callRecordingMode: 'NEVER',
        playAnnouncement: false,
        recordVoiceMessaging: false
    },
    alternateNumbers: [],
    welcomeMessageFileName: '',
    hideFromPhoneBook: false,
    hidePhoneStatus: false,
    allowChangeOutgoingCli: true
})

export const AddUser = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const history = useHistory()
    const defaultNcos = useSelector(defaultNcosSelector)
    const {buildFormButtons} = useFormSubmissionButtons(true)

    const onSubmit = useCallback(
        async (formData: PhoneUserForm) => {
            if (!site) return
            await api.post(`/api/site/${site.productId}/user`, {
                ...formData,
                nonCpeDevices: formData.gbtfProfile === 'INTERNAL'? [] : formData.nonCpeDevices?.filter(d => d),
                busyLampUserIds: formData.busyLampUsers?.map(user => user.uuid).join(','),
                permittedMonitorUserIds: formData.permittedMonitorUsers?.map(user => user.uuid).join(','),
                fmuMsisdn: formData.gbtfProfile === 'INTERNAL' ? null : formData.fmuMsisdn,
                callRecordingUserSettings: formData.gbtfProfile === 'INTERNAL' ? defaultCallRecording : formData.callRecordingUserSettings,
                prioExternalCalls: formData.gbtfProfile === 'INTERNAL' ? false : formData.prioExternalCalls,
                alternateNumbers: formData.alternateNumbers.map(nr => nr.number),
            })
            history.push('/users')
        },
        [site, history]
    )

    return (
        <TitledBody title={t('users.add.title')}>
            <Grid container>
                <Grid item xs={12} lg={9}>
                    <UserForm onSubmit={onSubmit} user={initialUser(defaultNcos)} buildFormButtons={buildFormButtons(<CancelButton/>)}/>
                </Grid>
            </Grid>
        </TitledBody>
    )
}
