import {CPE, ExtensionValidationRule} from '../app/types'
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material'
import {TFunction, useTranslation} from 'react-i18next'
import CancelButton from '../common/form/CancelButton'
import React, {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {
    extensionValidationRulesSelector,
    nrOfExtensionDigitsSelector,
    selectedSiteSelector
} from '../authentication/redux'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import {api} from '../common/api'
import ErrorAlert from '../common/components/ErrorAlert'
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useFormSubmission} from "../common/servercall/useFormSubmission";
import TextInput from "../common/form/TextInput";
import SpinnableSubmitButton from "../common/form/SpinnableSubmitButton";
import {Box, Flex} from "rebass";
import {extensionRegex} from "../users/UserForm";

type Props = {
    cpe: CPE | null
    handleClose: () => void
    onSuccess: () => void
}

type HotDeskForm = {
    extension: string | null
}

const schema = (t: TFunction, nrOfExtensionDigits: number, extensionValidationRules: ExtensionValidationRule[]) =>
    yup.object()
    .shape({
        extension: yup.lazy(value => {
            let schema = yup
                .string()
                .trim()
                .required('required-f')
                .matches(extensionRegex(nrOfExtensionDigits), t("general.form-error.invalid-extension", {nrOfExtensionDigits}))
            extensionValidationRules.forEach(rule => {
                schema = schema.test({
                    name: 'test',
                    test: value => !value || !new RegExp(`^${rule.regex}$`).test(value),
                    message: rule.errorKey,
                })
            })
            return schema.nullable()
        }),
    })
    .required()

export const HotDeskDialog = ({ cpe, handleClose, onSuccess }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const nrOfExtensionDigits = useSelector(nrOfExtensionDigitsSelector)
    const extensionValidationRules = useSelector(extensionValidationRulesSelector)

    const { control, handleSubmit, setError } = useForm<HotDeskForm>({
        resolver: yupResolver(schema(t, nrOfExtensionDigits, extensionValidationRules)),
        defaultValues: { extension: cpe?.hotDeskExtension },
    })

    const onSubmit = useCallback(
        async (formData: HotDeskForm) => {
            if (!site || !cpe) return
            if (!!cpe.hotDeskExtension) {
                await api.put(`/api/site/${site.productId}/cpe/${cpe.uuid}/hot-desk`, formData)
            } else {
                await api.post(`/api/site/${site.productId}/cpe/${cpe.uuid}/hot-desk`, formData)
            }

            handleClose()
            onSuccess()
        },
        [site, cpe, handleClose, onSuccess]
    )

    const deleteHotDesk = useCallback(
        async () => {
            if (!site || !cpe) return
            await api.delete(`/api/site/${site.productId}/cpe/${cpe.uuid}/hot-desk`)
            handleClose()
            onSuccess()
        },
        [site, cpe, handleClose, onSuccess]
    )

    const { submit, isSubmitting, serverError } = useFormSubmission<HotDeskForm>(onSubmit, setError)

    return (
        <Dialog open={!!cpe} onClose={handleClose} fullWidth={true}>
            <form onSubmit={handleSubmit(submit)} noValidate>
                <DialogTitle>
                    {`${t('cpes.hot-desk-dialog.title')} ${cpe?.identifier}`}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ErrorAlert showAlert={!!serverError} />
                    {!serverError && !!cpe?.userUuid &&
                        <Flex mb={2}>
                            <Box width={1}>
                                <Alert severity={'warning'}>
                                    {t('cpes.hot-desk-dialog.hot-desk-user-attached-warning')}
                                </Alert>
                            </Box>
                        </Flex>
                    }
                    <Grid container spacing={2} flexDirection={'column'} alignItems={'flex-start'}>
                        <Grid item xs={6}>
                            <TextInput label={t('users.edit.extension')} name={'extension'} control={control} required={true} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={!!cpe?.hotDeskExtension ? 'space-between' : "flex-end"}>
                        {!!cpe?.hotDeskExtension ? <Grid item>
                            <Button variant='contained' onClick={deleteHotDesk} color={'error'}>
                                {t('cpes.hot-desk-dialog.remove-hot-desk-button')}
                            </Button>
                        </Grid> : <></>
                        }
                        <Grid item>
                            <SpinnableSubmitButton label={!!cpe?.hotDeskExtension ? t('general.form-update') : t('cpes.hot-desk-dialog.create-button')} showSpinner={isSubmitting} />
                            <CancelButton onClick={handleClose} />
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    )
}
