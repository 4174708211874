import React, {useEffect, useState} from 'react'
import {api} from '../../api'
import {Grid, IconButton, LinearProgress, Typography} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import axios from 'axios'
import {useTranslation} from 'react-i18next'

export type Props = {
    file: File
    uploadUrl: string
    onSuccess: (file: File) => void
    onDelete: (file: File) => void
    frontErrorCode?: string
}

export function FileUploadWithProgress({ file, uploadUrl, onSuccess, onDelete, frontErrorCode }: Props) {
    const [progress, setProgress] = useState(0)
    const [errorCode, setErrorCode] = useState<string | null>(null)
    const [success, setSuccess] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
        async function upload() {
            try {
                if (frontErrorCode) {
                    setErrorCode(`general.form-error.${frontErrorCode}`)
                } else {
                    await uploadFile(file, uploadUrl, setProgress)
                    setSuccess(true)
                    onSuccess(file)
                }
            } catch (err) {
                if (axios.isAxiosError(err)) {
                    const data = err?.response?.data as [any]
                    err.response?.status === 400 ? !!data ? setErrorCode(`general.form-error.${data[0].key}`) :
                            setErrorCode('general.form-error.file-too-large')
                        : setErrorCode('general.unknown-server-error')
                } else {
                    setErrorCode('general.unknown-server-error')
                }
            }
        }

        upload()
    }, [file, uploadUrl, onSuccess, frontErrorCode])

    return (
        <Grid item width={1}>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>{file.name}</Grid>
                {errorCode && (
                    <Grid item>
                        <IconButton size={'small'} onClick={e => onDelete(file)}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
            <LinearProgress variant={'determinate'} value={progress}
                            color={!!errorCode ? 'error' : success ? 'success' : 'primary'} />
            {errorCode && <Typography color='error'>{t(errorCode)}</Typography>}
            {success && <Typography color='success'>{t('general.file-uploaded-successfully')}</Typography>}
        </Grid>
    )
}

async function uploadFile(file: File, uploadUrl: string, onProgressChange: (progressPercentage: number) => void) {
    const formData = new FormData()
    formData.append('file', file)

    await api.post(uploadUrl, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function(progressEvent) {
            if (progressEvent.lengthComputable) {
                const percentage = (progressEvent.loaded / (progressEvent?.total || 1)) * 100
                onProgressChange(Math.round(percentage))
            }
        },
    })
}
