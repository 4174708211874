import {ClickAwayListener, Tooltip} from "@mui/material";
import {ReactElement, useState} from "react";

export default function ClickableTooltip({title, buttonFn} : {title: React.ReactNode, buttonFn: (onClick: () => void) => ReactElement}) {
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };


  return (
      <div>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={title}
            >
                {buttonFn(handleTooltipOpen)}
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
  )
}