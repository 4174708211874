import React, {ReactElement, ReactNode, useCallback} from 'react'
import {FormSection} from "../common/components/SectionedForm";
import {api} from "../common/api";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../common/form/PaperStack";
import FormGroup from "../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../common/components/LabelValues";
import {AnswerGroupForm} from "../app/types";
import {formatNumberToForwardTo, formatPhoneNumber, formatUser} from "../common/util/formatters";
import {EnableBusySwitch} from "./EnableBusySwitch";
import {Paper, Table, TableContainer} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {UserLink} from "../users/UserLink";
import {AgentAvailabilitySwitch} from "./AgentAvailabilitySwitch";
import {DEFAULT_ANNOUNCEMENT} from "../common/util/helpers";
import {SelectiveCallForwardDetail} from "../common/components/SelectiveCallForwardDetail";
import {usePhoneNumbersToForwardTo} from "../common/servercall/usePhoneNumbersToForwardTo";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import {useFormSubmissionButtons} from "../common/form/useFormSubmissmionButtons";
import {FlowForm} from "./FlowForm";
import {useAccessHelpers} from "../common/util/hooks";

export const useGeneralSection = (answerGroupId: string, isFetching: boolean, error: any, refetch: () => void, data?: AnswerGroupForm) : FormSection<AnswerGroupForm> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {phoneNumbersToForwardTo} = usePhoneNumbersToForwardTo(site)
    const {buildFormButtons} = useFormSubmissionButtons(false)
    const {hasWriteAccess} = useAccessHelpers()

    const onSubmit = useCallback((onSucceeded: () => void) =>
        async (formData: AnswerGroupForm) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/answergroup/${answerGroupId}`, {
                ...formData,
                agentUserIds: formData.userAgents?.map(agent => agent.uuid).join(','),
                voicemailEnabled: formData.overflowDestination === 'VOICEMAIL' ? true : formData.voicemailEnabled,
            })
            await refetch()
            onSucceeded();
        },
        [site, answerGroupId, refetch]
    )

    const renderGeneralForm = useCallback(
        (flow: AnswerGroupForm, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
            <FlowForm flow={flow} onSubmit={onSubmit(onSucceeded)} buildFormButtons={buildFormButtons(cancelEditButton)}/>
        , [onSubmit, buildFormButtons])

    const infoLabelValues = useCallback(
        (value: AnswerGroupForm): LabelValueType[] => [
            {
                label: t('flows.table.type'),
                value: <>{t(`flows.type.${value.answerGroupType}`)}</>,
            },
            { label: t('flows.edit.name'), value: <>{value.name}</> },
            { label: t('users.edit.language'), value: <>{t(`users.language.${value.language}`)}</> },
            {
                label: t('flows.edit.number'),
                value: <>{value.number ? formatPhoneNumber(value.number) : t('general.none')}</>,
            },
            { label: t('flows.edit.extension'), value: <>{value.extension || t('general.none')}</> },
            ...(value.answerGroupType === 'QUEUE'
                ? [
                    {
                        label: t('flows.edit.queue-length'),
                        value: <>{`${value.queueLength}`}</>,
                    },
                ]
                : []),
            {
                label: t('users.edit.ncos'),
                value: <>{t(`users.ncos-names.${value.ncos}`)}</>,
            },
            {
                label: t('users.edit.prio-external-calls'),
                value: <>{value.prioExternalCalls ? t('general.yes') : t('general.no')}</>,
            },
            ...(value.answerGroupType === 'HUNT_GROUP'
                ? [
                    {
                        label: t('flows.edit.busy-enabled'),
                        value: <EnableBusySwitch readonly={!hasWriteAccess} onSwitch={async (enabled) => {
                            if (!!site) {
                                await api.put(`/api/site/${site.productId}/answergroup/${value.uuid}/toggle-enable-busy`, {
                                    enableBusy: enabled
                                })
                            }
                        }} enabled={value.enableGroupBusy || false}
                                                 disabledSuccessMessage={t('flows.edit.enable-busy-switch-off-success')}
                                                 enabledSuccessMessage={t('flows.edit.enable-busy-switch-on-success')}
                        />
                    },
                    {
                        label: t('flows.edit.allow-members-to-control-group-busy'),
                        value: <EnableBusySwitch readonly={!hasWriteAccess} onSwitch={async (enabled) => {
                            if (!!site) {
                                await api.put(`/api/site/${site.productId}/answergroup/${value.uuid}/toggle-allow-members-group-busy`, {
                                    enable: enabled
                                })
                            }
                        }} enabled={value.allowMembersToControlGroupBusy || false}
                                                 disabledSuccessMessage={t('flows.edit.enable-members-control-busy-switch-off-success')}
                                                 enabledSuccessMessage={t('flows.edit.enable-members-control-busy-switch-on-success')}
                        />
                    }
                ]
                : []),
        ],
        [t, site, hasWriteAccess]
    )

    const voicemail = useCallback(
        (value: AnswerGroupForm): LabelValueType[] => [
            {
                label: t('flows.edit.voicemail-enabled'),
                value: <>{value.voicemailEnabled ? t('general.yes') : t('general.no')}</>,
            },
            ...(value.answerGroupType === 'QUEUE'
                ? [
                    {
                        label: t('flows.edit.overflow-timeout'),
                        value: <>{`${value.overflowTimeout} ${t('general.seconds-abbrev')}`}</>,
                    },
                ]
                : [
                    {
                        label: t('flows.edit.forward-timeout'),
                        value: <>{`${value.forwardTimeoutSeconds} ${t('general.seconds-abbrev')}`}</>,
                    },
                ]),
            { label: t('flows.edit.voicemail-usage'), value: <>{t(`flows.edit.voicemail-usages.${value.voicemailUsage}`)}</> },
            { label: t('flows.edit.voicemail-email'),
                value: <>{`${value.voicemailEmail || t('general.none')}${value.voicemailUsage === 'FORWARD_TO_EMAIL'? `;${value.additionalVoicemailEmails}` : ''}`}</>,
                type: value.voicemailUsage === 'FORWARD_TO_EMAIL' ? 'FULL_LINE' : 'NORMAL'
            },
        ],
        [t]
    )

    const agents = useCallback(
        (value: AnswerGroupForm): LabelValueType[] => [
            {
                label: '',
                value: (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 300 }} size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 700 }}>{t('flows.table.name')}</TableCell>
                                    {value.answerGroupType === 'QUEUE' ? (
                                        <TableCell style={{ fontWeight: 700 }} align="right">
                                            {t('flows.detail.available')}
                                        </TableCell>
                                    ) : (
                                        <></>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {value.userAgents.map(it =>
                                    <TableRow key={it.uuid} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row">
                                            <UserLink uuid={it.uuid} siteProductId={it.siteProductId} formattedUser={formatUser(it)}/>
                                        </TableCell>
                                        {value.answerGroupType === 'QUEUE' ? (
                                            <TableCell align="right">
                                                <AgentAvailabilitySwitch
                                                    readonly={!hasWriteAccess}
                                                    available={it.available}
                                                    userBusinessId={it.userBusinessId}
                                                    answerGroupId={value.uuid}
                                                    queue={value.answerGroupType === 'QUEUE'}
                                                />
                                            </TableCell>
                                        ) : (
                                            <></>
                                        )}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ),
                type: 'FULL_LINE',
            },
            {
                label: t('flows.edit.ring-time-per-agent'),
                value: <>{`${value.agentRingTime} ${t('general.seconds-abbrev')}`}</>,
            },
            {
                label: t('flows.edit.hunt-policy'),
                value: <>{t(`flows.edit.hunting-policy-names.${value.huntPolicy}`)}</>,
            },
            ...(value.answerGroupType === 'QUEUE'
                ? [
                    {
                        label: t('flows.edit.allow-agent-logoff'),
                        value: <>{value.allowAgentLogoff ? t('general.yes') : t('general.no')}</>,
                    },
                ]
                : []),
            {
                label: t('flows.edit.allow-call-waiting-for-agents'),
                value: <>{value.allowCallWaitingForAgents ? t('general.yes') : t('general.no')}</>,
            },
        ],
        [t, hasWriteAccess]
    )

    const media = useCallback(
        (value: AnswerGroupForm): LabelValueType[] => [
            {
                label: t('flows.edit.welcome-message'),
                value: <>{value.welcomeMessageFileName || t('general.none')}</>,
            },
            ...(value.answerGroupType === 'QUEUE'
                ? [
                    {
                        label: t('flows.edit.music-on-hold'),
                        value: <>{value.musicOnHoldFileName ? `${(value.musicOnHoldFileName === DEFAULT_ANNOUNCEMENT ? t('general.default') : value.musicOnHoldFileName)}${value.playRingingWhenOfferingCall ? ` (${t('flows.edit.play-ringing-when-offering-call')})` : ''}` : t('general.none')}</>,
                    },
                    {
                        label: t('flows.edit.comfort-media-file'),
                        value: (
                            <>
                                {value.comfortMessageFileName
                                    ? t('flows.detail.comfort-message', {
                                        file: value.comfortMessageFileName,
                                        frequency: value.comfortMessageGapTime,
                                    })
                                    : t('general.none')}
                            </>
                        ),
                    },
                ]
                : []),
        ],
        [t]
    )

    const formatOverflowNumber = useCallback((extension?: string | null) => formatNumberToForwardTo(t, extension || null, phoneNumbersToForwardTo), [phoneNumbersToForwardTo, t])

    const overflow = useCallback(
        (value: AnswerGroupForm): LabelValueType[] => [
            {
                label: t('flows.edit.overflow-destination'),
                value: (
                    <>
                        {value.overflowDestination === 'CALL_FORWARD'
                            ? t('flows.detail.call-forward-to', {
                                number: formatOverflowNumber(value.overflowNumber),
                            })
                            : value.overflowDestination === 'BUSY_TREATMENT'
                                ? t('flows.detail.announcement-when-busy', {
                                    announcement: value.overflowAnnouncementFileName,
                                })
                                : t(`flows.edit.overflow-destination-names.${value.overflowDestination}`)}
                    </>
                ),
            },
            ...(value.overflowDestination === 'VOICEMAIL'
                ? [
                    {
                        label: t('flows.edit.voicemail-greeting'),
                        value: <>{value.voicemailNoAnswerAnnouncement === DEFAULT_ANNOUNCEMENT ? t('general.default') : value.voicemailNoAnswerAnnouncement}</>,
                    },
                ]
                : []),
            ...(value.overflowDestination === 'CALL_FORWARD'
                ? [
                    {
                        label: t('flows.edit.call-forward-announcement'),
                        value: <>{value.callForwardAnnouncement || t('general.none')}</>,
                    },
                ]
                : []),
        ],
        [t, formatOverflowNumber]
    )

    const renderDetails = useCallback(
        (answerGroup: AnswerGroupForm): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={t('flows.edit.section.info')} fullWidth={true}>
                        <LabelValues labelValues={infoLabelValues(answerGroup)} />
                    </FormGroup>
                    <FormGroup key={2} label={t('flows.edit.section.voicemail')} fullWidth={true}>
                        <LabelValues labelValues={voicemail(answerGroup)} />
                    </FormGroup>
                    <FormGroup key={3} label={t('flows.edit.section.agents')} fullWidth={true}>
                        <LabelValues labelValues={agents(answerGroup)} />
                    </FormGroup>
                    <FormGroup key={4} label={t('flows.edit.section.media')} fullWidth={true}>
                        <LabelValues labelValues={media(answerGroup)} />
                    </FormGroup>
                    <FormGroup key={5} label={t('flows.edit.section.overflow')} fullWidth={true}>
                        <LabelValues labelValues={overflow(answerGroup)} />
                    </FormGroup>
                    <SelectiveCallForwardDetail key={6} selectiveCallForward={answerGroup.selectiveCallForward || null}
                                                nightServiceMode={answerGroup.nightServiceMode} phoneNumbersToForwardTo={phoneNumbersToForwardTo} fullWidth={true} />
                </PaperStack>
            )
        },
        [infoLabelValues, voicemail, agents, media, overflow, t, phoneNumbersToForwardTo]
    )

    return {
        title: t('general.general'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderDetails,
        renderForm: renderGeneralForm,
        enabled: () => true,
        icon: () => <CallSplitIcon/>
    }
}