import React, {ReactElement} from 'react'
import {Box} from '@mui/material'
import {Flex} from "rebass";

type Props = {
    children: ReactElement[]
    lastChildUnboxed?: boolean
}
export default function PaperStack({ children, lastChildUnboxed = false }: Props) {
    return (
        <Flex flexDirection={'column'}>
            {children.map((child, index) => (
                lastChildUnboxed && index === children.length - 1 ?
                    child :
                    <Box sx={{mb: index === children.length - 1 ? 0 : {xs: 2, md: 3}}} key={index}>
                        {child}
                    </Box>
            ))}
        </Flex>
    )
}
