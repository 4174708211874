import React, {useCallback, useEffect, useMemo} from 'react'

import {IvrSubMenuFormFriendly} from '../../app/types'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import * as yup from 'yup'
import {array, object, string} from 'yup'
import {Box, Flex} from 'rebass'
import Divider from '@mui/material/Divider'
import {default as MaterialBox} from '@mui/material/Box/Box'
import FormButtons from '../../common/form/FormButtons'
import SpinnableSubmitButton from '../../common/form/SpinnableSubmitButton'
import CancelButton from '../../common/form/CancelButton'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import FormGroup from '../../common/form/FormGroup'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import TextInput from '../../common/form/TextInput'
import PaperStack from '../../common/form/PaperStack'
import {getAutocompleteSiteAnnouncements} from '../../common/util/helpers'
import IvrSubMenuKeys from './IvrSubmenuKeys'
import {IvrMenuConfigContext} from './IvrMenuConfigForm'
import {reverse} from 'ramda'

const schema = yup
    .object()
    .shape({
        id: yup.string().trim().max(40, 'too-long').required('required-m'),
        announcementFileName: yup.string().nullable().required('required-m'),
        keyActions: array().of(
            object().shape({
                gbtfIvrSubMenuAction: string().required('required-m'),
                config: yup.lazy(value => {
                    return yup.object().when('gbtfIvrSubMenuAction', action => {
                        if (['USER', 'ANSWER_GROUP', 'IVR'].includes(action)) {
                            switch (typeof value) {
                                case 'object':
                                    return yup.object().nullable().required('required-m')
                                case 'string':
                                    return yup.string().nullable().required('required-m')
                                default:
                                    return yup.string().nullable().required('required-m')
                            }
                        } else if ('NUMBER' === action) {
                            return yup
                                .string()
                                .nullable()
                                .required('required-m')
                                .matches(/^\+[1-9]\d{2,14}$|^\d{3,15}$/, {
                                    message: 'invalid-phone-number',
                                    excludeEmptyString: true,
                                })
                        } else if ('EXTENSION' === action) {
                            return yup
                                .string()
                                .nullable()
                                .required('required-m')
                                .matches(/^[0-9]{4,5}$/, {
                                    message: 'invalid-extension',
                                    excludeEmptyString: true,
                                })
                        } else if (['MENU', 'ANNOUNCEMENT'].includes(action)) {
                            return yup.string().nullable().required('required-m')
                        }
                        return yup.string().nullable()
                    })
                }),
            }),
        ),
    })
    .nullable()

interface Props {
    ivrSubMenu: IvrSubMenuFormFriendly
    onSubmit: (formData: IvrSubMenuFormFriendly) => void
    onCancel: () => void
}

export const IvrSubMenuForm = ({ ivrSubMenu, onSubmit, onCancel }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const { control, register, handleSubmit, setError, clearErrors, trigger } = useForm<IvrSubMenuFormFriendly>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...ivrSubMenu,
        },
    })

    useEffect(() => {
        ivrSubMenu && ivrSubMenu.id && trigger()
    }, [ivrSubMenu, trigger])

    const { menuConfig } = React.useContext(IvrMenuConfigContext)

    const announcementOptionParams = useMemo(() => {
        return getAutocompleteSiteAnnouncements(t, 2000, site, ivrSubMenu.announcementFileName ?? undefined)
    }, [ivrSubMenu, site, t])

    const { onError } = useFormSubmission<IvrSubMenuFormFriendly>(onSubmit, setError)

    const submit = useCallback(
        async (values: IvrSubMenuFormFriendly) => {
            try {
                const isNewSubMenu = !ivrSubMenu.id
                const newName = !isNewSubMenu && ivrSubMenu.id !== values.id
                const shouldCheckName = isNewSubMenu || newName
                if (shouldCheckName && !!menuConfig?.subMenus && !!menuConfig?.subMenus[values.id]) {
                    //unique name?
                    setError('id', { type: 'client', message: 'ivr.submenu-id-already-exists' })
                    return
                } else if (shouldCheckName && values.keyActions?.length) {
                    //forwarding to own menu?
                    const menuActionKeysWithSameName = values.keyActions?.filter(it => it.gbtfIvrSubMenuAction === 'MENU' && it.config === values.id)
                    if (menuActionKeysWithSameName?.length) {
                        menuActionKeysWithSameName.forEach(it => {
                            setError(`keyActions.${values.keyActions.findIndex(action => action.menuKey === it.menuKey)}.config`, {
                                type: 'client',
                                message: 'ivr.forward-to-own-or-parent-menu-not-allowed',
                            })
                        })
                        return
                    }
                } else if (values.keyActions?.length) {
                    const duplicateKeys = values.keyActions?.filter((item, index) => values.keyActions.findIndex(item2 => item2.menuKey === item.menuKey) !== index)
                    if (duplicateKeys?.length) {
                        duplicateKeys.forEach(it => {
                            setError(`keyActions.${values.keyActions.length - 1 - reverse(values.keyActions).findIndex(action => action.menuKey === it.menuKey)}.menuKey`, {
                                type: 'client',
                                message: 'ivr.duplicate-key',
                            })
                        })
                        return
                    }
                }

                await onSubmit(values)
            } catch (err) {
                console.error(err)
            }
        },
        [onSubmit, ivrSubMenu.id, setError, menuConfig?.subMenus],
    )

    return (
        // @ts-ignore
        <FormProvider clearErrors={clearErrors}>
            <form className={'ant-form ant-form-vertical'} onSubmit={handleSubmit(submit, onError)} noValidate={true}>
                <Flex height={'100%'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Box>
                        {/*<ErrorAlert showAlert={!!serverError} />*/}
                        <PaperStack>
                            <FormGroup label={t('ivrs.sub-menu-editor.section.general')} fullWidth={true}>
                                <FormRow>
                                    <FormFieldBox>
                                        <TextInput autofocus={true} label={t('ivrs.sub-menu-editor.name')} name={'id'}
                                                   control={control} required={true} />
                                    </FormFieldBox>
                                    <FormFieldBox>
                                        <TextInput
                                            help={t('general.max-file-size', {size: 2})}
                                            label={t('ivrs.sub-menu-editor.message')}
                                            name={'announcementFileName'}
                                            control={control}
                                            required={true}
                                            autoCompleteOptions={announcementOptionParams}
                                        />
                                    </FormFieldBox>
                                </FormRow>
                            </FormGroup>
                            <FormGroup label={t('ivrs.sub-menu-editor.section.selection-menu')} fullWidth={true}>
                                <IvrSubMenuKeys ivrSubMenu={ivrSubMenu} control={control} register={register} />
                            </FormGroup>
                        </PaperStack>
                    </Box>
                    <Box>
                        <Divider variant='middle' />
                        <MaterialBox sx={{ mt: 2 }}>
                            <FormButtons
                                buttons={[<SpinnableSubmitButton label={t('general.ok')} showSpinner={false} />,
                                    <CancelButton onClick={onCancel} />]} fullWidth={true} />
                        </MaterialBox>
                    </Box>
                </Flex>
            </form>
        </FormProvider>
    )
}
