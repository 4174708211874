import {ReactElement, useEffect, useRef} from 'react'
import {useSelector} from "react-redux";
import {hasWriteAccessSelector, meSelector, selectedSiteSelector} from "../../authentication/redux";

export const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = useRef<() => void>()

    useEffect(() => {
        savedCallback.current = callback
    })

    useEffect(() => {
        function tick() {
            savedCallback?.current && savedCallback.current()
        }

        if (delay !== null) {
            tick()
            let id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}

export const useUrlHelpers = () : {createUrl : ((postfix: string) => string)} => {
    const site = useSelector(selectedSiteSelector)
    const me = useSelector(meSelector)

    return {
        createUrl: (postfix) => `/api/${site? `site/${site.productId}` : `enterprise/${me?.voiceCloudProductId}`}/${postfix}`
    }
}


export const useAccessHelpers = () : {hasWriteAccess: boolean, onlyWithWriteAccess : (element: ReactElement) => ReactElement} => {
    const hasWriteAccess = useSelector(hasWriteAccessSelector)

    return {
        hasWriteAccess: hasWriteAccess || false,
        onlyWithWriteAccess: (element) => hasWriteAccess ? element : <></>
    }
}


