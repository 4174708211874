import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {BlackListedNumber, ServerPage} from '../../app/types'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {api, buildPaginatedUrl} from '../../common/api'
import {Sort, useInfiniteScroll} from '../../common/components/table/useInfiniteScroll'
import {ColumnDefinition, InfiniteScrollTable} from '../../common/components/table/Table'
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material'
import CancelButton from '../../common/form/CancelButton'
import {useAccessHelpers, useUrlHelpers} from "../../common/util/hooks";
import TableRowActionsMenu from "../../common/components/table/TableRowActionsMenu";
import {sorterHeader} from "../../common/components/table/SorterHeader";
import {AddButton} from "../../common/components/AddButton";
import ErrorAlert from "../../common/components/ErrorAlert";
import DeletionModal from "../../common/components/DeletionModal";
import useBlackListedNumberForm from "./useBlackListedNumberForm";

export const BlackListedNumbers = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [entryToDelete, setEntryToDelete] = useState<BlackListedNumber | null>(null)
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
    const {createUrl} = useUrlHelpers();
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()

    const fetchEntries = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<BlackListedNumber>> => {
            const { data } = await api.get(buildPaginatedUrl(createUrl('blacklisted-numbers'), pageParam, sortParam, searchTermParam))
            return data
        },
        [createUrl]
    )

    const { sort, setSort, rows, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteScroll<BlackListedNumber>({
        queryKey: `blacklisted-numbers-${site?.productId}`,
        initialSort: { field: 'startNumber', order: 'asc' },
        fetchFunction: fetchEntries,
    })

    const onSubmit = useCallback(
        async (formData: BlackListedNumber) => {
            const url = createUrl(`blacklisted-numbers`)
            await api.post(url, formData)
            setOpenCreateModal(false)
            await refetch()
        },
        [refetch, createUrl]
    )

    const { handleSubmit, form: Form, submitButton: SubmitButton, reset } = useBlackListedNumberForm({ onSubmit })

    const rowActions = useMemo(
        () => [
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: BlackListedNumber) => {
                    setEntryToDelete(record)
                },
                needsWriteAccess: true
            },
        ],
        [t]
    )

    // @ts-ignore
    const columns: ColumnDefinition<BlackListedNumber>[] = useMemo(
        () => [
            {
                title: t('blacklisted-number.table.number'),
                key: 'startNumber',
                sorter: false,
                render: (text: string) => <>{text}</>,
            },
            {
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: BlackListedNumber) => <TableRowActionsMenu record={record} menuItems={rowActions} readOnly={!hasWriteAccess}/>,
            }
        ],
        [t, rowActions, hasWriteAccess]
    )

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(entry => entry.uuid, [])

    const getDeleteUrl = useCallback(i => i ? createUrl(`blacklisted-numbers/${i.startNumber}`) : null, [createUrl])

    const buttonPanel = useMemo(
        () => (
            <Grid container item justifyContent={'space-between'} flexDirection={'row'} spacing={2} flexWrap={'nowrap'}>
                {onlyWithWriteAccess(<Grid item>
                        <AddButton
                            onClick={async () => {
                                setOpenCreateModal(true)
                                reset()
                                return null
                            }}
                            label={`${t('blacklisted-number.create-new')}`}
                        />
                    </Grid>)
                }
            </Grid>
        ),
        [t, onlyWithWriteAccess, reset]
    )

    const entryForm = useMemo(
        () => (
            <form className={'ant-form ant-form-vertical'} onSubmit={handleSubmit} noValidate={true}>
                <Dialog disablePortal open={openCreateModal} onClose={() => setOpenCreateModal(false)}>
                    <DialogTitle>{t('blacklisted-number.create-new')}</DialogTitle>
                    <DialogContent>
                        <Form />
                    </DialogContent>
                    <DialogActions>
                        <SubmitButton />
                        <CancelButton onClick={() => setOpenCreateModal(false)} />
                    </DialogActions>
                </Dialog>
            </form>
        ),
        [Form, SubmitButton, openCreateModal, handleSubmit, t]
    )

    return (
        <>
            <TitledBody title={t('navigation.blacklisted-numbers')} buttonsPanel={buttonPanel}>
                <ErrorAlert showAlert={!!error} />
                <InfiniteScrollTable<BlackListedNumber>
                    hasNextPage={hasNextPage}
                    data={rows}
                    fetchNextPage={fetchNextPage}
                    isFetching={isFetching}
                    getRowKey={getRowKey}
                    sorter={sorter}
                    columns={columns}
                    tableId={'phonebookTable'}
                />
                <DeletionModal<BlackListedNumber | null> itemToDelete={entryToDelete} deleteUrl={getDeleteUrl} onClose={() => setEntryToDelete(null)} onSuccess={refetch} />
                {entryForm}
            </TitledBody>
        </>
    )
}
