import React, {ReactElement} from 'react'
import {TFunction, useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from '../../common/form/TextInput'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import SpinnableSubmitButton from '../../common/form/SpinnableSubmitButton'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import ErrorAlert from '../../common/components/ErrorAlert'
import {UseFormSetValue} from 'react-hook-form/dist/types/form'
import {BlackListedNumber} from "../../app/types";
import {useSelector} from "react-redux";
import {nrOfExtensionDigitsSelector} from "../../authentication/redux";

const schema = (t: TFunction, nrOfExtensionDigits: number) => yup
    .object()
    .shape({
        startNumber: yup
            .string()
            .trim()
            .max(22, 'too-long')
            .required('required-m')
            .matches(new RegExp(`^[0-9]{${nrOfExtensionDigits}}$|^\\+[1-9]\\d{2,14}$`), 'invalid-entry-number'),
    })
    .required()

type Props = {
    onSubmit: (formData: BlackListedNumber) => void
}

export type ReturnProps = {
    handleSubmit: () => void
    form: () => ReactElement
    submitButton: () => ReactElement
    reset: () => void
    setValue: UseFormSetValue<BlackListedNumber>
}

const useBlackListNumberForm = ({ onSubmit }: Props): ReturnProps => {
    const { t } = useTranslation()
    const nrOfExtensionDigits = useSelector(nrOfExtensionDigitsSelector)

    const { control, handleSubmit, setError, reset, setValue } = useForm<BlackListedNumber>({
        resolver: yupResolver(schema(t, nrOfExtensionDigits)),
        defaultValues: {},
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<BlackListedNumber>(onSubmit, setError)

    return {
        setValue: setValue,
        reset: reset,
        handleSubmit: handleSubmit(submit, onError),
        form: () =>
                <>
                    <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError} />
                    <FormRow>
                        <FormFieldBox fullWidth={true}>
                            <TextInput label={t('blacklisted-number.table.number')} name={'startNumber'} control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                </>,
        submitButton: () => (<SpinnableSubmitButton label={t('general.form-create')} showSpinner={isSubmitting} />),
    }
}

export default useBlackListNumberForm
