import React, {ReactElement} from 'react'
import PaperStack from './PaperStack'

type Props = {
    onSubmit: () => void
    children: any,
    noPaperStack?: boolean
}

export const formBuilder = (onSubmit: () => void) => (children : any) : ReactElement => {
    return <FormContainer onSubmit={onSubmit}>
        {children}
    </FormContainer>
}

export default function FormContainer({ onSubmit, children, noPaperStack = false }: Props) {
    return (
        <form className={'ant-form ant-form-vertical'} onSubmit={onSubmit} noValidate={true} >
            {noPaperStack ? { children } : <PaperStack lastChildUnboxed={true}>{children}</PaperStack>}
        </form>
    )
}
